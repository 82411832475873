@import "variables/varPTH-icons";
@import "variables/varColores-General";
@import "variables/varGeneral";
@import "partials/PTH-icons";

#ptw-offers .ptw-grid:before,
#ptw-offers .ptw-nav:before,
#ptw-travel-styles .ptw-grid:before,
#ptw-travel-styles .ptw-nav:before,
#ptw-destination-guides .ptw-grid:before,
#ptw-destination-guides .ptw-nav:before,
#ptw-container.ptw-menu-vertical:before,
#ptw-container #ptw-menu > ul:before,
#ptw-container .ptw-field-group:before,
#ptw-container #ptw-variants:before,
#ptw-container #ptw-variants .ap_booker_variantGroup:before,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination:before,
#ptw-container.ptw-horizontal-search .ptw-fields-container:before,
#ptw-container.ptw-horizontal-search #ap_booker_multipleDestinations .ptw-flight-container:before,
#ptw-offers .ptw-offer-item > a:before,
#hotelOffers .row:before,
#hotelOffers .picture-list li:before,
#topDestinations .nav:before,
#ptw-destination-guides .ptw-dguides-item:before,
#ptw-offers .ptw-grid:after,
#ptw-offers .ptw-nav:after,
#ptw-travel-styles .ptw-grid:after,
#ptw-travel-styles .ptw-nav:after,
#ptw-destination-guides .ptw-grid:after,
#ptw-destination-guides .ptw-nav:after,
#ptw-container.ptw-menu-vertical:after,
#ptw-container #ptw-menu > ul:after,
#ptw-container .ptw-field-group:after,
#ptw-container #ptw-variants:after,
#ptw-container #ptw-variants .ap_booker_variantGroup:after,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination:after,
#ptw-container.ptw-horizontal-search .ptw-fields-container:after,
#ptw-container.ptw-horizontal-search #ap_booker_multipleDestinations .ptw-flight-container:after,
#ptw-offers .ptw-offer-item > a:after,
#hotelOffers .row:after,
#hotelOffers .picture-list li:after,
#topDestinations .nav:after,
#ptw-destination-guides .ptw-dguides-item:after {
  content: " ";
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
}
#ptw-offers .ptw-grid:after,
#ptw-offers .ptw-nav:after,
#ptw-travel-styles .ptw-grid:after,
#ptw-travel-styles .ptw-nav:after,
#ptw-destination-guides .ptw-grid:after,
#ptw-destination-guides .ptw-nav:after,
#ptw-container.ptw-menu-vertical:after,
#ptw-container #ptw-menu > ul:after,
#ptw-container .ptw-field-group:after,
#ptw-container #ptw-variants:after,
#ptw-container #ptw-variants .ap_booker_variantGroup:after,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination:after,
#ptw-container.ptw-horizontal-search .ptw-fields-container:after,
#ptw-container.ptw-horizontal-search #ap_booker_multipleDestinations .ptw-flight-container:after,
#ptw-offers .ptw-offer-item > a:after,
#hotelOffers .row:after,
#hotelOffers .picture-list li:after,
#topDestinations .nav:after,
#ptw-destination-guides .ptw-dguides-item:after {
  clear: both;
}
#ptw-container.ptw-menu-vertical #ptw-menu,
#ptw-container.ptw-menu-vertical #ptw-content,
#ptw-container #ptw-menu > ul > li,
#hotelOffers *,
#topDestinations * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
#ptw-container .ptw-label,
.contentOffers .colI h4 {
  color: #fff;
  background: #aaa;
  display: inline-block;
  padding: 0 0.6em;
  font-size: 85%;
  line-height: 1.8em;
  text-align: center;
  white-space: nowrap;
}
#ptw-container,
#ptw-slideshow,
#topDestinations,
#hotelOffers,
#ptw-search-history,
#ptw-offers,
#ptw-travel-styles,
#ptw-destination-guides,
#ptw-payment-options {
  margin: 0;
  padding: 0;
  background: #fff;
  color: #333;
  font-size: 13px;
  text-align: left;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
#ptw-container *,
#ptw-slideshow *,
#topDestinations *,
#hotelOffers *,
#ptw-search-history *,
#ptw-offers *,
#ptw-travel-styles *,
#ptw-destination-guides *,
#ptw-payment-options * {
  margin: 0;
  padding: 0;
  line-height: 100%;
}
#ptw-container td,
#ptw-container th,
#ptw-slideshow td,
#ptw-slideshow th,
#topDestinations td,
#topDestinations th,
#hotelOffers td,
#hotelOffers th,
#ptw-search-history td,
#ptw-search-history th,
#ptw-offers td,
#ptw-offers th,
#ptw-travel-styles td,
#ptw-travel-styles th,
#ptw-destination-guides td,
#ptw-destination-guides th,
#ptw-payment-options td,
#ptw-payment-options th {
  border: none;
  background: transparent;
}
#ptw-container a,
#ptw-slideshow a,
#topDestinations a,
#hotelOffers a,
#ptw-search-history a,
#ptw-offers a,
#ptw-travel-styles a,
#ptw-destination-guides a,
#ptw-payment-options a {
  text-decoration: none;
  color: #06c;
}
#ptw-container a:hover,
#ptw-slideshow a:hover,
#topDestinations a:hover,
#hotelOffers a:hover,
#ptw-search-history a:hover,
#ptw-offers a:hover,
#ptw-travel-styles a:hover,
#ptw-destination-guides a:hover,
#ptw-payment-options a:hover {
  text-decoration: none;
  color: #004080;
}
#ptw-container a:active,
#ptw-container a:hover,
#ptw-slideshow a:active,
#ptw-slideshow a:hover,
#topDestinations a:active,
#topDestinations a:hover,
#hotelOffers a:active,
#hotelOffers a:hover,
#ptw-search-history a:active,
#ptw-search-history a:hover,
#ptw-offers a:active,
#ptw-offers a:hover,
#ptw-travel-styles a:active,
#ptw-travel-styles a:hover,
#ptw-destination-guides a:active,
#ptw-destination-guides a:hover,
#ptw-payment-options a:active,
#ptw-payment-options a:hover {
  outline: 0;
}
#ptw-container button,
#ptw-container input,
#ptw-container select,
#ptw-container textarea,
#ptw-slideshow button,
#ptw-slideshow input,
#ptw-slideshow select,
#ptw-slideshow textarea,
#topDestinations button,
#topDestinations input,
#topDestinations select,
#topDestinations textarea,
#hotelOffers button,
#hotelOffers input,
#hotelOffers select,
#hotelOffers textarea,
#ptw-search-history button,
#ptw-search-history input,
#ptw-search-history select,
#ptw-search-history textarea,
#ptw-offers button,
#ptw-offers input,
#ptw-offers select,
#ptw-offers textarea,
#ptw-travel-styles button,
#ptw-travel-styles input,
#ptw-travel-styles select,
#ptw-travel-styles textarea,
#ptw-destination-guides button,
#ptw-destination-guides input,
#ptw-destination-guides select,
#ptw-destination-guides textarea,
#ptw-payment-options button,
#ptw-payment-options input,
#ptw-payment-options select,
#ptw-payment-options textarea {
  font-size: 100%;
  margin: 0;
}
#ptw-container button,
#ptw-container input,
#ptw-slideshow button,
#ptw-slideshow input,
#topDestinations button,
#topDestinations input,
#hotelOffers button,
#hotelOffers input,
#ptw-search-history button,
#ptw-search-history input,
#ptw-offers button,
#ptw-offers input,
#ptw-travel-styles button,
#ptw-travel-styles input,
#ptw-destination-guides button,
#ptw-destination-guides input,
#ptw-payment-options button,
#ptw-payment-options input {
  line-height: normal;
}
#ptw-container input,
#ptw-slideshow input,
#topDestinations input,
#hotelOffers input,
#ptw-search-history input,
#ptw-offers input,
#ptw-travel-styles input,
#ptw-destination-guides input,
#ptw-payment-options input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#ptw-container table,
#ptw-slideshow table,
#topDestinations table,
#hotelOffers table,
#ptw-search-history table,
#ptw-offers table,
#ptw-travel-styles table,
#ptw-destination-guides table,
#ptw-payment-options table {
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
  background: transparent;
}
#ptw-container ol,
#ptw-container ul,
#ptw-slideshow ol,
#ptw-slideshow ul,
#topDestinations ol,
#topDestinations ul,
#hotelOffers ol,
#hotelOffers ul,
#ptw-search-history ol,
#ptw-search-history ul,
#ptw-offers ol,
#ptw-offers ul,
#ptw-travel-styles ol,
#ptw-travel-styles ul,
#ptw-destination-guides ol,
#ptw-destination-guides ul,
#ptw-payment-options ol,
#ptw-payment-options ul {
  list-style: none;
}
#ptw-container .ptw-light,
#ptw-container .ptw-note,
#ptw-slideshow .ptw-light,
#ptw-slideshow .ptw-note,
#topDestinations .ptw-light,
#topDestinations .ptw-note,
#hotelOffers .ptw-light,
#hotelOffers .ptw-note,
#ptw-search-history .ptw-light,
#ptw-search-history .ptw-note,
#ptw-offers .ptw-light,
#ptw-offers .ptw-note,
#ptw-travel-styles .ptw-light,
#ptw-travel-styles .ptw-note,
#ptw-destination-guides .ptw-light,
#ptw-destination-guides .ptw-note,
#ptw-payment-options .ptw-light,
#ptw-payment-options .ptw-note {
  color: gray;
  font-size: 85%;
}
#ptw-container .ptw-hide,
#ptw-slideshow .ptw-hide,
#topDestinations .ptw-hide,
#hotelOffers .ptw-hide,
#ptw-search-history .ptw-hide,
#ptw-offers .ptw-hide,
#ptw-travel-styles .ptw-hide,
#ptw-destination-guides .ptw-hide,
#ptw-payment-options .ptw-hide {
  display: none;
}
#ptw-container .ptw-alert,
#ptw-slideshow .ptw-alert,
#topDestinations .ptw-alert,
#hotelOffers .ptw-alert,
#ptw-search-history .ptw-alert,
#ptw-offers .ptw-alert,
#ptw-travel-styles .ptw-alert,
#ptw-destination-guides .ptw-alert,
#ptw-payment-options .ptw-alert {
  background: #fcf8e3;
  border: 1px solid #faebcc;
  padding: 0.7em 0.7em 0 0.7em;
  margin-bottom: 1em;
  font-size: 85%;
  color: #444;
}
#ptw-container .ptw-alert p,
#ptw-slideshow .ptw-alert p,
#topDestinations .ptw-alert p,
#hotelOffers .ptw-alert p,
#ptw-search-history .ptw-alert p,
#ptw-offers .ptw-alert p,
#ptw-travel-styles .ptw-alert p,
#ptw-destination-guides .ptw-alert p,
#ptw-payment-options .ptw-alert p {
  margin-bottom: 0.7em;
  line-height: 1.3em;
}
#ptw-container .ptw-ovrlay,
#ptw-container .ptw-popover {
  position: absolute;
  z-index: 5;
}
#ptw-container .ptw-ovrlay {
  margin: 1px 0;
}
#ptw-container .ptw-popover {
  top: 0;
  left: 0;
  display: none;
  width: 380px;
  padding: 15px;
  margin-top: 12px;
  background: #fff;
  border: 1px solid #ddd;
}
#ptw-container .ptw-popover:after,
#ptw-container .ptw-popover:before {
  bottom: 100%;
  left: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
#ptw-container .ptw-popover:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 5px;
  margin-left: -15px;
}
#ptw-container .ptw-popover:before {
  border-color: rgba(221, 221, 221, 0);
  border-bottom-color: #ccc;
  border-width: 6px;
  margin-left: -16px;
}
#ptw-container #ptw-popover-close {
  background: #f3f3f3;
  padding: 3px;
}
#ptw-container .ptw-dropdown-link {
  position: relative;
  padding-right: 14px;
}
#ptw-container .ptw-dropdown-link:after {
  display: inline-block;
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 0;
  height: 0;
  margin: 0 5px;
  vertical-align: middle;
  border-top: 4px solid rgba(0, 0, 0, 0.8);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  box-shadow: 0 0 15px #fff;
}
#ptw-container .flight-package-pax-message {
  display: none;
}
#ptw-offers *,
#ptw-offers *:after,
#ptw-offers *:before,
#ptw-travel-styles *,
#ptw-travel-styles *:after,
#ptw-travel-styles *:before,
#ptw-destination-guides *,
#ptw-destination-guides *:after,
#ptw-destination-guides *:before {
  box-sizing: border-box;
}
#ptw-offers .ptw-grid,
#ptw-travel-styles .ptw-grid,
#ptw-destination-guides .ptw-grid {
  margin: 0 -5px;
  padding: 0;
}
#ptw-offers .ptw-grid li,
#ptw-travel-styles .ptw-grid li,
#ptw-destination-guides .ptw-grid li {
  float: left;
  padding: 5px;
  min-width: 210px;
  max-width: 390px;
  width: 25%;
}
#ptw-offers .ptw-grid li a,
#ptw-travel-styles .ptw-grid li a,
#ptw-destination-guides .ptw-grid li a {
  display: block;
  background: #f3f3f3;
  text-decoration: none;
  padding: 10px;
  color: #333;
}
#ptw-offers .ptw-grid li a:hover,
#ptw-travel-styles .ptw-grid li a:hover,
#ptw-destination-guides .ptw-grid li a:hover {
  text-decoration: none;
}
#ptw-offers .ptw-grid li a > img,
#ptw-offers .ptw-grid li a > span,
#ptw-offers .ptw-grid li a > strong,
#ptw-travel-styles .ptw-grid li a > img,
#ptw-travel-styles .ptw-grid li a > span,
#ptw-travel-styles .ptw-grid li a > strong,
#ptw-destination-guides .ptw-grid li a > img,
#ptw-destination-guides .ptw-grid li a > span,
#ptw-destination-guides .ptw-grid li a > strong {
  display: block;
}
#ptw-offers .ptw-grid li a > img,
#ptw-travel-styles .ptw-grid li a > img,
#ptw-destination-guides .ptw-grid li a > img {
  min-width: 100%;
  margin-bottom: 10px;
}
#ptw-offers .ptw-nav,
#ptw-travel-styles .ptw-nav,
#ptw-destination-guides .ptw-nav {
  margin: 0;
  padding-top: 0;
}
#ptw-offers .ptw-nav li,
#ptw-travel-styles .ptw-nav li,
#ptw-destination-guides .ptw-nav li {
  float: left;
  padding: 0 !important;
  line-height: 2em;
  margin-bottom: 10px;
}
#ptw-offers .ptw-nav li a,
#ptw-travel-styles .ptw-nav li a,
#ptw-destination-guides .ptw-nav li a {
  display: block;
  padding: 0 10px;
  line-height: 2em;
}
#ptw-offers .ptw-nav .ptw-active a,
#ptw-travel-styles .ptw-nav .ptw-active a,
#ptw-destination-guides .ptw-nav .ptw-active a {
  font-weight: bold;
}
#ptw-container #ptw-content {
  background: #fff;
}
#ptw-container #ptw-variants .ap_booker_variantGroup,
#ptw-container #ptw-engines {
  padding: 0.5em;
}
#ptw-container.ptw-small {
  font-size: 12px;
}
#ptw-container.ptw-small .ptw-light {
  font-size: 100%;
}
#ptw-container.ptw-large {
  font-size: 14px;
}
#ptw-container.ptw-large .ptw-field-destination,
#ptw-container.ptw-large .ptw-field-origin,
#ptw-container.ptw-large .ptw-field-dates,
#ptw-container.ptw-large .ptw-field-rooms-pax {
  float: left;
  width: 48%;
}
#ptw-container.ptw-large .ptw-field-origin,
#ptw-container.ptw-large .ptw-field-dates {
  margin-right: 4%;
}
#ptw-container.ptw-large #ap_booker_Hotel .ptw-field-destination,
#ptw-container.ptw-large #ap_booker_Hotel .ptw-field-origin,
#ptw-container.ptw-large #ptw-hotel .ptw-field-destination,
#ptw-container.ptw-large #ptw-hotel .ptw-field-origin {
  width: 100%;
  float: none;
  margin-right: 0;
}
#ptw-container.ptw-large #ap_booker_multiFlightContainer .ptw-field-origin,
#ptw-container.ptw-large #ap_booker_multiFlightContainer .ptw-field-destination,
#ptw-container.ptw-large #ptw-multiflight .ptw-field-origin,
#ptw-container.ptw-large #ptw-multiflight .ptw-field-destination {
  width: 38%;
  margin-right: 2%;
}
#ptw-container.ptw-large #ap_booker_multiFlightContainer #ptw-multiflight .ptw-field-dates,
#ptw-container.ptw-large #ptw-flight #ptw-multiflight .ptw-field-dates {
  width: 20%;
  margin-right: 0;
  float: left;
}
#ptw-container.ptw-large #ap_booker_multiFlightContainer #ptw-multiflight .ptw-field-dates .ptw-field-date,
#ptw-container.ptw-large #ptw-flight #ptw-multiflight .ptw-field-dates .ptw-field-date {
  width: 100%;
}
#ptw-container.ptw-menu-vertical #ptw-menu,
#ptw-container.ptw-menu-vertical #ptw-content {
  float: left;
}
#ptw-container.ptw-menu-vertical #ptw-menu {
  width: 27%;
  height: 100%;
}
#ptw-container.ptw-menu-vertical #ptw-menu li,
#ptw-container.ptw-menu-vertical #ptw-menu a {
  float: none;
}
#ptw-container.ptw-menu-vertical #ptw-content {
  width: 73%;
  padding-left: 3%;
  min-height: 220px;
}
#ptw-container.ptw-large.ptw-menu-vertical #ptw-menu {
  width: 22%;
}
#ptw-container.ptw-large.ptw-menu-vertical #ptw-content {
  width: 78%;
}
#ptw-container.ptw-large.ptw-menu-vertical .ptw-field-dates {
  float: none;
  width: 100%;
}
#ptw-container .ui-helper-hidden {
  display: none;
}
#ptw-container .ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
#ptw-container .ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}
#ptw-container .ui-helper-clearfix:before,
#ptw-container .ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}
#ptw-container .ui-helper-clearfix:after {
  clear: both;
}
#ptw-container .ui-helper-clearfix {
  min-height: 0;
}
#ptw-container .ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
}
#ptw-container .ui-front {
  z-index: 100;
}
.pt-customJqueryUi .ui-state-disabled {
  cursor: default !important;
}
.pt-customJqueryUi .ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pt-customJqueryUi .ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
}
.pt-customJqueryUi .ui-datepicker {
  width: 17em;
  padding: 0.2em 0.2em 0;
  display: none;
}
.pt-customJqueryUi .ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: 0.2em 0;
}
.pt-customJqueryUi .ui-datepicker .ui-datepicker-prev,
.pt-customJqueryUi .ui-datepicker .ui-datepicker-next {
  background: #999;
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;
  border-radius: 3px;
}
.pt-customJqueryUi .ui-datepicker .ui-datepicker-prev {
  left: 2px;
}
.pt-customJqueryUi .ui-datepicker .ui-datepicker-next {
  right: 2px;
}
.pt-customJqueryUi .ui-datepicker .ui-datepicker-prev span,
.pt-customJqueryUi .ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -2px;
  top: 50%;
  margin-top: -5px;
  text-indent: -999em;
}
.pt-customJqueryUi .ui-datepicker .ui-datepicker-prev span {
  margin-left: -3px;
}
.pt-customJqueryUi .ui-icon-circle-triangle-w,
.pt-customJqueryUi .ui-icon-circle-triangle-e {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  margin-top: 22px;
  margin-left: 7px;
}
.pt-customJqueryUi .ui-icon-circle-triangle-w {
  border-right: 5px solid #fff;
}
.pt-customJqueryUi .ui-icon-circle-triangle-e {
  border-left: 5px solid #fff;
}
.pt-customJqueryUi .ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
}
.pt-customJqueryUi .ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}
.pt-customJqueryUi .ui-datepicker select.ui-datepicker-month-year {
  width: 100%;
}
.pt-customJqueryUi .ui-datepicker select.ui-datepicker-month,
.pt-customJqueryUi .ui-datepicker select.ui-datepicker-year {
  width: 49%;
}
.pt-customJqueryUi .ui-datepicker table {
  width: 100%;
  font-size: 0.9em;
  border-collapse: collapse;
  margin: 0 0 0.4em;
}
.pt-customJqueryUi .ui-datepicker th {
  padding: 0.7em 0.3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}
.pt-customJqueryUi .ui-datepicker td {
  border: 0;
  padding: 0 0 1px 1px;
}
.pt-customJqueryUi .ui-datepicker td span,
.pt-customJqueryUi .ui-datepicker td a {
  display: block;
  padding: 0.3em 0.2em;
  text-align: right;
  text-decoration: none;
}
.pt-customJqueryUi .ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: 0.7em 0 0 0;
  padding: 0 0.2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
.pt-customJqueryUi .ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: 0.5em 0.2em 0.4em;
  cursor: pointer;
  padding: 0.2em 0.6em 0.3em 0.6em;
  width: auto;
  overflow: visible;
}
.pt-customJqueryUi .ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}
.pt-customJqueryUi .ui-datepicker.ui-datepicker-multi {
  width: auto;
}
.pt-customJqueryUi .ui-datepicker-multi .ui-datepicker-group {
  float: left;
}
.pt-customJqueryUi .ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto 0.4em;
}
.pt-customJqueryUi .ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}
.pt-customJqueryUi .ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}
.pt-customJqueryUi .ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}
.pt-customJqueryUi .ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.pt-customJqueryUi .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}
.pt-customJqueryUi .ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}
.pt-customJqueryUi .ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}
.pt-customJqueryUi .ui-datepicker-rtl {
  direction: rtl;
}
.pt-customJqueryUi .ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}
.pt-customJqueryUi .ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}
.pt-customJqueryUi .ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}
.pt-customJqueryUi .ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}
.pt-customJqueryUi .ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}
.pt-customJqueryUi .ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}
.pt-customJqueryUi .ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.pt-customJqueryUi .ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}
.pt-customJqueryUi .ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.pt-customJqueryUi .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}
.pt-customJqueryUi .ui-menu {
  list-style: none;
  padding: 2px;
  margin: 0;
  display: block;
  outline: none;
}
.pt-customJqueryUi .ui-menu .ui-menu {
  margin-top: -3px;
  position: absolute;
}
.pt-customJqueryUi .ui-menu .ui-menu-item {
  margin: 0;
  padding: 0;
  width: 100%;
}
.pt-customJqueryUi .ui-menu .ui-menu-divider {
  margin: 5px -2px 5px -2px;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0;
}
.pt-customJqueryUi .ui-menu .ui-menu-item a {
  text-decoration: none;
  display: block;
  padding: 2px 0.4em;
  line-height: 1.5;
  min-height: 0;
  font-weight: normal;
}
.pt-customJqueryUi .ui-menu .ui-menu-item a.ui-state-focus,
.pt-customJqueryUi .ui-menu .ui-menu-item a.ui-state-active {
  font-weight: normal;
}
.pt-customJqueryUi .ui-menu .ui-state-disabled {
  font-weight: normal;
  margin: 0.4em 0 0.2em;
  line-height: 1.5;
}
.pt-customJqueryUi .ui-menu .ui-state-disabled a {
  cursor: default;
}
.pt-customJqueryUi .ui-menu .ui-menu-item .numHotels {
  float: right;
  font-size: 85%;
  filter: alpha(opacity=80);
  opacity: 0.8;
}
.pt-customJqueryUi .ui-menu-icons {
  position: relative;
}
.pt-customJqueryUi .ui-menu-icons .ui-menu-item a {
  position: relative;
  padding-left: 2em;
}
.pt-customJqueryUi .ui-menu .ui-icon {
  position: absolute;
  top: 0.2em;
  left: 0.2em;
}
.pt-customJqueryUi .ui-menu .ui-menu-icon {
  position: static;
  float: right;
}
.pt-customJqueryUi .ui-widget {
  font-size: 13px;
}
.pt-customJqueryUi .ui-widget .ui-widget {
  font-size: 1em;
}
.pt-customJqueryUi .ui-widget input,
.pt-customJqueryUi .ui-widget select,
.pt-customJqueryUi .ui-widget textarea,
.pt-customJqueryUi .ui-widget button {
  font-size: 1em;
}
.pt-customJqueryUi .ui-widget-content {
  border: 1px solid #ddd;
  background: #fff;
  color: #333;
}
.pt-customJqueryUi .ui-widget-content a {
  color: #333;
}
.pt-customJqueryUi .ui-widget-header {
  border: 1px solid #ddd;
  background: #ededed;
  color: #333;
  font-weight: bold;
}
.pt-customJqueryUi .ui-widget-header a {
  color: #333;
}
.pt-customJqueryUi .ui-state-default,
.pt-customJqueryUi .ui-widget-content .ui-state-default,
.pt-customJqueryUi .ui-widget-header .ui-state-default {
  background: #eee;
  font-weight: normal;
  color: #555;
}
.pt-customJqueryUi .ui-state-default a,
.pt-customJqueryUi .ui-state-default a:link,
.pt-customJqueryUi .ui-state-default a:visited {
  color: #555;
  text-decoration: none;
}
.pt-customJqueryUi .ui-state-hover,
.pt-customJqueryUi .ui-widget-content .ui-state-hover,
.pt-customJqueryUi .ui-widget-header .ui-state-hover,
.pt-customJqueryUi .ui-state-focus,
.pt-customJqueryUi .ui-widget-content .ui-state-focus,
.pt-customJqueryUi .ui-widget-header .ui-state-focus {
  font-weight: normal;
  background-color: #314c67;
  color: #fff;
}
.pt-customJqueryUi .ui-state-hover a,
.pt-customJqueryUi .ui-state-hover a:hover,
.pt-customJqueryUi .ui-state-hover a:link,
.pt-customJqueryUi .ui-state-hover a:visited {
  text-decoration: none;
}
.pt-customJqueryUi .ui-state-active a,
.pt-customJqueryUi .ui-state-active a:link,
.pt-customJqueryUi .ui-state-active a:visited {
  text-decoration: none;
}
.pt-customJqueryUi .ui-widget-content .ptw-date-active a {
  background-color: #cad8e6;
  color: #000;
}
.pt-customJqueryUi .ui-widget-content .ptw-start-date a,
.pt-customJqueryUi .ui-widget-content .ptw-end-date a,
.pt-customJqueryUi .ui-state-active,
.pt-customJqueryUi .ui-widget-content .ui-state-active,
.pt-customJqueryUi .ui-widget-header .ui-state-active {
  font-weight: normal;
  background-color: #41668a;
  color: #fff;
}
.pt-customJqueryUi .ui-widget-content .ptw-start-date a,
.pt-customJqueryUi .ui-widget-content .ptw-end-date a {
  position: relative;
}
.pt-customJqueryUi .ui-widget-content .ptw-start-date a:after,
.pt-customJqueryUi .ui-widget-content .ptw-end-date a:after {
  top: 50%;
  border-top: 0.8em solid transparent;
  border-bottom: 0.8em solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  margin-top: -0.8em;
}
.pt-customJqueryUi .ui-widget-content .ptw-start-date a:after {
  left: 100%;
  border-left: 0.4em solid #41668a;
}
.pt-customJqueryUi .ui-widget-content .ptw-end-date a:after {
  right: 100%;
  border-right: 0.4em solid #41668a;
}
.pt-customJqueryUi .ui-state-highlight,
.pt-customJqueryUi .ui-widget-content .ui-state-highlight,
.pt-customJqueryUi .ui-widget-header .ui-state-highlight {
  font-weight: bold;
}
.pt-customJqueryUi .ui-state-highlight a,
.pt-customJqueryUi .ui-widget-content .ui-state-highlight a,
.pt-customJqueryUi .ui-widget-header .ui-state-highlight a {
  color: #555;
}
.pt-customJqueryUi .ui-state-error,
.pt-customJqueryUi .ui-widget-content .ui-state-error,
.pt-customJqueryUi .ui-widget-header .ui-state-error {
  background: #f2dede;
  color: #b94a48;
}
.pt-customJqueryUi .ui-state-error a,
.pt-customJqueryUi .ui-widget-content .ui-state-error a,
.pt-customJqueryUi .ui-widget-header .ui-state-error a {
  color: #b94a48;
}
.pt-customJqueryUi .ui-state-error-text,
.pt-customJqueryUi .ui-widget-content .ui-state-error-text,
.pt-customJqueryUi .ui-widget-header .ui-state-error-text {
  color: #b94a48;
}
.pt-customJqueryUi .ui-priority-primary,
.pt-customJqueryUi .ui-widget-content .ui-priority-primary,
.pt-customJqueryUi .ui-widget-header .ui-priority-primary {
  font-weight: bold;
}
.pt-customJqueryUi .ui-priority-secondary,
.pt-customJqueryUi .ui-widget-content .ui-priority-secondary,
.pt-customJqueryUi .ui-widget-header .ui-priority-secondary {
  opacity: 0.7;
  filter: Alpha(Opacity=70);
  font-weight: normal;
}
.pt-customJqueryUi .ui-state-disabled,
.pt-customJqueryUi .ui-widget-content .ui-state-disabled,
.pt-customJqueryUi .ui-widget-header .ui-state-disabled {
  opacity: 0.5;
  filter: Alpha(Opacity=50);
  background-image: none;
}
.pt-customJqueryUi .ui-state-disabled .ui-icon {
  filter: Alpha(Opacity=35);
}
.pt-customJqueryUi .ui-widget-overlay {
  background: #000000;
  opacity: 0.3;
  filter: Alpha(Opacity=30);
}
.pt-customJqueryUi .ui-widget-shadow {
  margin: 0 0 0 0;
  padding: 0;
  background: #aaaaaa;
  opacity: 0;
  filter: Alpha(Opacity=0);
  border-radius: 0;
}
.pt-customJqueryUi .ptw-resultPrediction li.title,
.pt-customJqueryUi .ptw-resultPrediction li.title:hover,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title:hover {
  text-transform: uppercase;
  color: gray;
  font: bold 1em/110% sans-serif;
  background: #f5f5f5;
  border-top: 1px solid #f5f5f5;
  cursor: default;
  padding: 2px 0.4em;
}
.pt-customJqueryUi .ptw-resultPrediction .location,
.pt-customJqueryUi .ptw-resultPrediction .ptw-item-location {
  font-size: 85%;
  color: #b3b3b3;
  display: block;
}
@media (max-width: 640px) {
  .pt-customJqueryUi .ui-datepicker-multi-2 .ui-datepicker-group,
  .pt-customJqueryUi .ui-datepicker-multi .ui-datepicker-group {
    width: 100%;
    float: none;
  }
}
@font-face {
  font-family: "ptw-icons";
  src: url("../fonts/ptw-icons.eot?whfet5");
  src:
    url("../fonts/ptw-icons.eot?#iefixwhfet5") format("embedded-opentype"),
    url("../fonts/ptw-icons.woff?whfet5") format("woff"),
    url("../fonts/ptw-icons.ttf?whfet5") format("truetype"),
    url("../fonts/ptw-icons.svg?whfet5#ptw-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PTH-icon";
  src: url(https://b2b-b2b2c.s3.amazonaws.com/general/fonts/pth-icon.eot);
  src:
    url(https://b2b-b2b2c.s3.amazonaws.com/general/fonts/pth-icon.eot?#iefix) format("embedded-opentype"),
    url(https://b2b-b2b2c.s3.amazonaws.com/general/fonts/pth-icon.woff) format("woff"),
    url(https://b2b-b2b2c.s3.amazonaws.com/general/fonts/pth-icon.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}
#ptw-container .icon,
.ptw-icon {
  font-family: "ptw-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #999;
}
a #ptw-container .icon,
a .ptw-icon {
  color: #999;
}
a:hover #ptw-container .icon,
a:hover .ptw-icon {
  color: #737373;
}
.ptw-icon-cars:before {
  content: "\e006";
}
.ptw-icon-heart:before {
  content: "\2665";
}
.ptw-icon-heart-line:before {
  content: "\2661";
}
.ptw-icon-cruises:before {
  content: "\e601";
}
.ptw-icon-halfstar:before {
  content: "\2606";
}
.ptw-icon-fullstar:before {
  content: "\2605";
}
.ptw-icon-video:before {
  content: "\e604";
}
.ptw-icon-list:before {
  content: "\e605";
}
.ptw-icon-search:before {
  content: "\e606";
}
.ptw-icon-rectangle:before {
  content: "\e607";
}
.ptw-icon-rectangle-half:before {
  content: "\e608";
}
.ptw-icon-rectangle-line:before {
  content: "\e609";
}
.ptw-icon-takeoff:before {
  content: "\e60a";
}
.ptw-icon-land:before {
  content: "\e60b";
}
.ptw-icon-offertag:before {
  content: "\e60d";
}
.ptw-icon-check:before {
  content: "\e60e";
}
.ptw-icon-info:before {
  content: "\e60f";
}
.ptw-icon-hotels:before {
  content: "\e610";
}
.ptw-icon-buses:before {
  content: "\e611";
}
#ptw-container .icon-calendar:before,
.ptw-icon-calendar:before {
  content: "\e612";
}
.ptw-icon-user:before {
  content: "\e613";
}
.ptw-icon-uparrow:before {
  content: "\25b2";
}
.ptw-icon-downarrow:before {
  content: "\25bc";
}
.ptw-icon-transfers:before {
  content: "\e616";
}
.ptw-icon-building:before {
  content: "\e617";
}
.ptw-icon-offer:before {
  content: "\e618";
}
.ptw-icon-plus:before {
  content: "\2b";
}
#ptw-container .icon-close:before,
.ptw-icon-close:before {
  content: "\2716";
}
.ptw-icon-flights:before {
  content: "\e61b";
}
.ptw-icon-tours:before {
  content: "\e61c";
}
.ptw-icon-mapmarker:before {
  content: "\e61d";
}
.ptw-icon-packages:before {
  content: "\e620";
}
.ptw-icon-globe-world:before {
  content: "\1f310";
}
.ptw-icon-compass:before {
  content: "\2600";
}
.ptw-icon-plane-airport:before {
  content: "\2708";
}
.ptw-icon-monument:before,
.ptw-icon-university:before {
  content: "\1f3db";
}
.ptw-icon-shield:before {
  content: "\26e8";
}
.ptw-icon-twostar:before {
  content: "\2605 \2605";
}
.ptw-icon-twohalf:before {
  content: "\2605 \2605 \2606";
}
.ptw-icon-threestar:before {
  content: "\2605 \2605 \2605";
}
.ptw-icon-threehalf:before {
  content: "\2605 \2605 \2605 \2606";
}
.ptw-icon-fourstar:before {
  content: "\2605 \2605 \2605 \2605";
}
.ptw-icon-fourhalf:before {
  content: "\2605 \2605 \2605 \2605 \2606";
}
.ptw-icon-fivestar:before {
  content: "\2605 \2605 \2605 \2605 \2605";
}
.ptw-icon-fivehalf:before {
  content: "\2605 \2605 \2605 \2605 \2605 \2606";
}
.ptw-icon-sixstar:before {
  content: "\2605 \2605 \2605 \2605 \2605 \2605";
}
.ptw-icon-twostar,
.ptw-icon-twohalf,
.ptw-icon-threestar,
.ptw-icon-threehalf,
.ptw-icon-fourstar,
.ptw-icon-fourhalf,
.ptw-icon-fivestar,
.ptw-icon-fivehalf,
.ptw-icon-sixstar {
  color: #fb0;
}
a .ptw-icon-twostar,
a:hover .ptw-icon-twostar,
a .ptw-icon-twohalf,
a:hover .ptw-icon-twohalf,
a .ptw-icon-threestar,
a:hover .ptw-icon-threestar,
a .ptw-icon-threehalf,
a:hover .ptw-icon-threehalf,
a .ptw-icon-fourstar,
a:hover .ptw-icon-fourstar,
a .ptw-icon-fourhalf,
a:hover .ptw-icon-fourhalf,
a .ptw-icon-fivestar,
a:hover .ptw-icon-fivestar,
a .ptw-icon-fivehalf,
a:hover .ptw-icon-fivehalf,
a .ptw-icon-sixstar,
a:hover .ptw-icon-sixstar {
  color: #fb0;
}
#ptw-container #ptw-menu > ul {
  border-bottom: 1px solid #5180ad;
  padding: 0.5em 0.5em 0 0.5em;
}
#ptw-container #ptw-menu > ul > li {
  margin-bottom: -1px;
}
#ptw-container #ptw-menu > ul > li > a {
  margin-right: 2px;
  line-height: 1.4em;
  text-decoration: none;
  position: relative;
  display: block;
  padding: 0.3em 0.8em;
  border: 1px solid transparent;
  border-bottom: 1px solid #5180ad;
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px;
  border-radius: 4px 4px 0 0;
  background-color: #fff;
  color: #000;
}
#ptw-container #ptw-menu > ul > li:hover > a,
#ptw-container #ptw-menu > ul > li > a:hover {
  background-color: #5180ad;
  color: #fff;
  text-decoration: none;
  border: 1px solid #5180ad;
  border-bottom: 1px solid #5180ad;
}
#ptw-container #ptw-menu > ul > li.ptw-active > a,
#ptw-container #ptw-menu > ul > li.ptw-active > a:hover,
#ptw-container #ptw-menu > ul > li.ptw-active > a:focus {
  border: 1px solid #5180ad;
  border-bottom-color: transparent;
  background-color: #fff;
  color: #000;
}
.ptw-menu-vertical#ptw-container #ptw-content {
  border-left: 1px solid #5180ad;
}
.ptw-menu-vertical#ptw-container #ptw-menu {
  padding: 0;
  background: none;
}
.ptw-menu-vertical#ptw-container #ptw-menu > ul {
  padding: 0.4em 0 0.4em 0.4em;
  border-bottom: none;
}
.ptw-menu-vertical#ptw-container #ptw-menu > ul > li > a {
  margin-right: -1px;
  border: 1px solid transparent;
  border-right: 1px solid #5180ad;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px 0 0 4px;
}
.ptw-menu-vertical#ptw-container #ptw-menu > ul > li:hover > a,
.ptw-menu-vertical#ptw-container #ptw-menu > ul > li > a:hover {
  border: 1px solid #5180ad;
  border-right: 1px solid #5180ad;
}
.ptw-menu-vertical#ptw-container #ptw-menu > ul > li.ptw-moreTab:hover > a,
.ptw-menu-vertical#ptw-container #ptw-menu > ul > li.ptw-moreTab > a:hover {
  -moz-border-radius: 4px 0 0 0;
  -webkit-border-radius: 4px;
  border-radius: 4px 0 0 0;
}
.ptw-menu-vertical#ptw-container #ptw-menu > ul > li.ptw-active > a,
.ptw-menu-vertical#ptw-container #ptw-menu > ul > li.ptw-active > a:hover,
.ptw-menu-vertical#ptw-container #ptw-menu > ul > li.ptw-active > a:focus {
  border: 1px solid #5180ad;
  border-right-color: transparent;
}
#ptw-container #ap_ptw_hiddenTabs {
  display: none;
  position: absolute;
  z-index: 10;
  padding: 0;
  margin-top: -1px;
  background-color: #5180ad;
  color: #fff;
}
#ptw-container #ap_ptw_hiddenTabs > li > a {
  display: block;
  padding: 0.5em 0.8em;
  background-color: #5180ad;
  color: #fff;
}
#ptw-container #ap_ptw_hiddenTabs > li > a:hover {
  background-color: #314c67;
  color: #fff;
}
#ptw-container #ap_ptw_hiddenTabs > li.ptw-active > a,
#ptw-container #ap_ptw_hiddenTabs > li.ptw-active > a:hover,
#ptw-container #ap_ptw_hiddenTabs > li.ptw-active > a:focus {
  border: none;
  border-bottom-color: transparent;
  background-color: #fff;
  color: #000;
}
#ptw-container .ptw-moreTab:hover ul#ap_ptw_hiddenTabs {
  display: block;
}
#ptw-container.ptw-menu-vertical #ap_ptw_hiddenTabs {
  width: 100%;
  margin-top: 0;
}
#ptw-container.ptw-menu-vertical #ap_ptw_hiddenTabs > li.ptw-active > a,
#ptw-container.ptw-menu-vertical #ap_ptw_hiddenTabs > li.ptw-active > a:hover,
#ptw-container.ptw-menu-vertical #ap_ptw_hiddenTabs > li.ptw-active > a:focus {
  border: none;
  border-right-color: transparent;
}
#ptw-container #ptw-menu {
  list-style: none;
  background: #fff;
}
#ptw-container #ptw-menu > ul > li {
  float: left;
  position: relative;
  display: block;
}
#ptw-container #ptw-menu li.ptw-active > a,
#ptw-container #ptw-menu li.ptw-active > a:hover,
#ptw-container #ptw-menu li.ptw-active > a:focus {
  cursor: default;
}
#ptw-container.ptw-menu-vertical #ptw-menu > ul > li {
  float: none;
  margin-bottom: 2px;
}
#ptw-container .ptw-submenu li {
  float: left;
  display: block;
}
#ptw-container .ptw-submenu ul {
  overflow: hidden;
  margin: 1em 0;
}
#ptw-container .ptw-submenu label {
  display: block;
  padding: 0 0.8em;
  line-height: 2.2em;
  *background-color: #d4d4d4;
  background-image: -moz-linear-gradient(top, #f8f8f8, #d4d4d4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f8f8f8), to(#d4d4d4));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #d4d4d4);
  background-image: -o-linear-gradient(top, #f8f8f8, #d4d4d4);
  background-image: linear-gradient(to bottom, #f8f8f8, #d4d4d4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$lightcolor', endColorstr='$darkcolor', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border: 1px solid #e6e6e6;
  border-bottom-color: silver;
  border-left: none;
  cursor: pointer;
  font-weight: normal;
}
#ptw-container .ptw-submenu li:first-child label {
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px 0 0 4px;
}
#ptw-container .ptw-submenu li:last-child label {
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0;
  border-radius: 0 4px 4px 0;
}
#ptw-container .ptw-submenu input {
  vertical-align: baseline;
}
#ptw-container .ptw-submenu .ptw-active label {
  color: #333;
  background: #e6e6e6;
  filter: none;
  -moz-box-shadow: inset 0 2px 3px #ccc;
  -webkit-box-shadow: inset 0 2px 3px #ccc;
  box-shadow: inset 0 2px 3px #ccc;
  font-weight: bold;
}
#ptw-container.ptw-small .ptw-submenu li,
#ptw-container.ptw-menu-vertical.ptw-medium .ptw-submenu li,
#ptw-container.ptw-menu-vertical.ptw-small .ptw-submenu li {
  float: none;
}
#ptw-container.ptw-small .ptw-submenu label,
#ptw-container.ptw-menu-vertical.ptw-medium .ptw-submenu label,
#ptw-container.ptw-menu-vertical.ptw-small .ptw-submenu label {
  padding: 0 0 0 1px;
  line-height: 1.6em;
  border: none;
  background: none;
}
#ptw-container.ptw-small .ptw-submenu .ptw-active label,
#ptw-container.ptw-menu-vertical.ptw-medium .ptw-submenu .ptw-active label,
#ptw-container.ptw-menu-vertical.ptw-small .ptw-submenu .ptw-active label {
  background: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
}
#ptw-container.ptw-menu-vertical .ptw-submenu ul {
  margin-top: 0;
}
#ptw-container .ptw-field {
  margin: 7px 0;
}
#ptw-container .ptw-field label {
  display: block;
  margin-bottom: 3px;
}
#ptw-container .ptw-field input,
#ptw-container .ptw-field select,
#ptw-container .ptw-discounts select,
#ptw-container .ptw-field-link {
  display: block;
  line-height: 1.2em;
  padding: 0.4em 0.3em;
  border: 1px solid #ccc;
  color: #333;
  background-color: #fff;
  font-size: 13px;
}
#ptw-container .ptw-dropdown-link {
  padding-right: 14px;
}
#ptw-container .ptw-field input {
  width: 100%;
  vertical-align: middle;
  height: auto;
}
#ptw-container .ptw-field select,
#ptw-container .ptw-discounts select {
  height: 2.4em;
  width: auto;
  max-width: 100%;
}
#ptw-container .ptw-field-group .ptw-field {
  float: left;
  margin-right: 4%;
}
#ptw-container .ptw-field-group .ptw-field:last-child {
  margin-right: 0;
}
#ptw-container .ptw-field-dates {
  max-width: 300px;
}
#ptw-container .ptw-field-dates .ptw-field {
  width: 48%;
}
#ptw-container .ptw-field-date {
  position: relative;
}
#ptw-container .ptw-field-date .icon-calendar,
#ptw-container .ptw-field-date .ptw-icon-calendar,
#ptw-container .ptw-field-date .ui-datepicker-trigger {
  right: 0;
  top: 0;
  margin-top: 1.5em;
  margin-right: 0.5em;
  position: absolute;
  font-size: 1.2em;
  cursor: pointer;
}
#ptw-container .ptw-field-date .ui-datepicker-trigger {
  width: 1em;
  height: 1em;
  display: block;
  z-index: 2;
}
#ptw-container .ptw-field-group .ptw-field input {
  min-width: 0;
  max-width: 150px;
}
#ptw-container .ptw-field-error input {
  background: #ffe;
}
#ptw-container .ptw-errormsg {
  font-size: 85%;
  color: #b00;
  display: block;
  margin-top: 3px;
}
#ptw-container .ptw-field-passengers th,
#ptw-container .ptw-field-passengers td {
  padding: 0 5px 3px 5px;
}
#ptw-container .ptw-field-passengers th {
  font-weight: normal;
}
#ptw-container .ptw-field-passengers label {
  color: #666;
}
#ptw-container .ptw-field-group .ptw-field input.ptw-date {
  padding-right: 1.5em;
}
#ptw-container .ptw-field-destination select {
  width: 100%;
  padding: 0.4em 0.3em;
}
#ptw-container .ptw-add-discount {
  margin-bottom: 0.8em;
}
#ptw-container .ptw-minors-age,
#ptw-container .ptw-add-discount {
  clear: both;
  margin-top: 0.8em;
  padding-top: 0.8em;
  border-top: 1px solid #ddd;
}
#ptw-container .ptw-field-ages {
  margin: 5px 0;
}
#ptw-container .ptw-minors-age-title {
  font-weight: bold;
}
#ptw-container .ptw-field-ages-title {
  clear: both;
  margin: 5px 0 0 0;
  color: #666;
}
#ptw-container .ptw-section-title {
  padding: 0.5em;
  margin-bottom: 10px;
  background-color: #eee;
  font-size: 12px;
}
#ptw-container .ptw-section-title .ptw-icon {
  font-size: 130%;
  vertical-align: middle;
}
#ptw-container .ptw-flight-container,
#ptw-container .ptw-hotel-container {
  border-bottom: 1px solid #ddd;
  padding: 10px 0 5px;
  overflow: hidden;
}
#ptw-container .ptw-flight-container:last-child,
#ptw-container .ptw-hotel-container:last-child {
  border-bottom: none;
}
#ptw-container .ptw-label {
  font-weight: bold;
  text-transform: uppercase;
}
#ptw-container .ptw-remove-link,
#ptw-container .ptw-add-link,
#ptw-container .ptw-remove-flight-link,
#ptw-container .ptw-add-flight {
  font-size: 85%;
}
#ptw-container .ptw-remove-link .ptw-icon,
#ptw-container .ptw-add-link .ptw-icon,
#ptw-container .ptw-remove-flight-link .ptw-icon,
#ptw-container .ptw-add-flight .ptw-icon {
  font-size: 130%;
  vertical-align: middle;
}
#ptw-container .ptw-remove-link,
#ptw-container .ptw-remove-flight-link {
  float: right;
}
#ptw-container .ptw-add-link,
#ptw-container .ptw-add-flight,
#ptw-container p.add-flight {
  margin: 5px 0;
  padding: 10px 0;
  border-top: 1px solid #ddd;
}
#ptw-container .ptw-discounts,
#ptw-container #flight_preferences {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0.5em 0;
}
#ptw-container .ptw-discounts .ap_passengerType {
  margin: 5px 0;
}
#ptw-container .ptw-discounts .ap_passengerType select {
  display: inline;
  height: 2em;
}
#ptw-container .ptw-discounts .ap_collection {
  margin-left: 5px;
}
#ptw-container .add-preferences a,
#ptw-container a.ap_Bus_addDiscount {
  font-size: 85%;
}
#ptw-container.ptw-small .add-preferences a,
#ptw-container.ptw-small a.ap_Bus_addDiscount {
  font-size: 100%;
}
#ptw-container #ptw-variants li {
  white-space: nowrap;
}
#ptw-container #ptw-variants .ap_booker_variantGroup {
  border-bottom: 1px solid #ddd;
}
#ptw-container #ptw-variants li.default,
#ptw-container #ptw-variants li.variantList {
  float: left;
  margin: 5px 0;
}
#ptw-container #ptw-variants li.default {
  width: 40%;
  margin-right: 5%;
  max-width: 100px;
}
#ptw-container #ptw-variants li.variantList {
  width: 60%;
}
#ptw-container #ptw-variants li.variantList li {
  margin: 0 0 5px 0;
}
#ptw-container #ptw-variants input:checked ~ label {
  font-weight: bold;
}
#ptw-container.ptw-small #ptw-variants li.default,
#ptw-container.ptw-small #ptw-variants li.variantList {
  float: none;
  width: 100%;
}
#ptw-container.ptw-large .ptw-field-date {
  max-width: 50%;
}
#ptw-container .ptw-card {
  background: #dfdfdf;
  padding: 0.5em;
  margin-right: -0.5em;
  border-radius: 0.25em 0 0 0.25em;
  position: relative;
}
#ptw-container .ptw-card .ptw-hotel-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 1.5em;
}
#ptw-container .ptw-card:after {
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  display: block;
  pointer-events: none;
  left: 100%;
  top: 50%;
  border: solid transparent;
  border-width: 1em 0.5em;
  margin-left: 0;
  margin-top: -1em;
  border-left-color: #dfdfdf;
}
#ptw-container .ptw-card .icon,
#ptw-container .ptw-card .ptw-icon {
  font-size: 80%;
  float: left;
  margin-top: 0.2em;
}
#ptw-container .ptw-card a:hover .icon,
#ptw-container .ptw-card a:hover .ptw-icon {
  color: #666;
}
#ptw-container .img-submit {
  width: 16px;
  height: 16px;
}
#ptw-container.ptw-horizontal-search * {
  box-sizing: border-box;
}
#ptw-container.ptw-horizontal-search .ptw-field select,
#ptw-container.ptw-horizontal-search .ptw-discounts select {
  height: 2.1em;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container {
  margin-right: -5px;
  margin-left: -5px;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination label,
#ptw-container.ptw-horizontal-search .ptw-fields-container label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination > .ptw-field,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination > .ptw-field-group,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination > .ptw-buttons,
#ptw-container.ptw-horizontal-search
  .ptw-fields-container
  #ap_booker_singleDestination
  > .ptw-field-bus-passengers
  .ptw-field,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination > .ptw-field-group .ptw-field,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination .ptw-field-date,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination .ptw-car-date,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination .ptw-car-time,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-field,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-field-group,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-buttons,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-field-bus-passengers .ptw-field,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-field-group .ptw-field,
#ptw-container.ptw-horizontal-search .ptw-fields-container .ptw-field-date,
#ptw-container.ptw-horizontal-search .ptw-fields-container .ptw-car-date,
#ptw-container.ptw-horizontal-search .ptw-fields-container .ptw-car-time {
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination > .ptw-field,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination > .ptw-field-group,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination > .ptw-buttons,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-field,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-field-group,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-buttons {
  width: 20%;
  min-width: 150px;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination > .ptw-field-group .ptw-field,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-field-group .ptw-field {
  margin-right: 0;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination > .ptw-field select,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-field select {
  width: 100%;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination > .ptw-field-group,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination > .ptw-field-dates,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-field-group,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-field-dates {
  padding-right: 0;
  padding-left: 0;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination .ptw-field-dates,
#ptw-container.ptw-horizontal-search .ptw-fields-container .ptw-field-dates {
  min-width: 170px;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination .ptw-field-date,
#ptw-container.ptw-horizontal-search .ptw-fields-container .ptw-field-date {
  width: 50%;
  min-width: 75px;
  margin-right: 0;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination .ptw-field-date .icon-calendar,
#ptw-container.ptw-horizontal-search
  .ptw-fields-container
  #ap_booker_singleDestination
  .ptw-field-date
  .ptw-icon-calendar,
#ptw-container.ptw-horizontal-search
  .ptw-fields-container
  #ap_booker_singleDestination
  .ptw-field-date
  .ui-datepicker-trigger,
#ptw-container.ptw-horizontal-search .ptw-fields-container .ptw-field-date .icon-calendar,
#ptw-container.ptw-horizontal-search .ptw-fields-container .ptw-field-date .ptw-icon-calendar,
#ptw-container.ptw-horizontal-search .ptw-fields-container .ptw-field-date .ui-datepicker-trigger {
  margin-right: 10px;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination > .ptw-single-date,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-single-date {
  width: 10%;
  min-width: 85px;
}
#ptw-container.ptw-horizontal-search
  .ptw-fields-container
  #ap_booker_singleDestination
  > .ptw-single-date
  .ptw-field-date,
#ptw-container.ptw-horizontal-search
  .ptw-fields-container
  #ap_booker_singleDestination
  > .ptw-single-date
  .ptw-field-date
  input,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-single-date .ptw-field-date,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-single-date .ptw-field-date input {
  width: 100%;
}
#ptw-container.ptw-horizontal-search
  .ptw-fields-container
  #ap_booker_singleDestination
  > .ptw-single-date
  .ptw-field-date
  input,
#ptw-container.ptw-horizontal-search .ptw-fields-container > .ptw-single-date .ptw-field-date input {
  max-width: 100%;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination .ptw-field-travelers,
#ptw-container.ptw-horizontal-search .ptw-fields-container .ptw-field-travelers {
  width: 15%;
  min-width: 100px;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination .ptw-buttons,
#ptw-container.ptw-horizontal-search .ptw-fields-container .ptw-buttons {
  width: auto;
  max-width: 25%;
  margin-top: 7px;
  padding-top: 1.1em;
  margin-bottom: 7px;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination .ptw-field-link,
#ptw-container.ptw-horizontal-search .ptw-fields-container .ptw-field-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
}
#ptw-container.ptw-horizontal-search
  .ptw-fields-container
  #ap_booker_singleDestination
  .ptw-field
  input::-webkit-input-placeholder,
#ptw-container.ptw-horizontal-search .ptw-fields-container .ptw-field input::-webkit-input-placeholder {
  font-size: 12px;
}
#ptw-container.ptw-horizontal-search #ap_booker_Hotel .ptw-fields-container > .ptw-field,
#ptw-container.ptw-horizontal-search #ap_booker_Hotel .ptw-fields-container > .ptw-field-dates {
  width: 25%;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination {
  width: 60%;
  float: left;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination .ptw-field-origin,
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination .ptw-field-destination {
  width: 35%;
}
#ptw-container.ptw-horizontal-search .ptw-fields-container #ap_booker_singleDestination .ptw-field-dates {
  width: 30%;
}
#ptw-container.ptw-horizontal-search #ap_booker_multipleDestinations .ptw-flight-container {
  margin-right: -5px;
  margin-left: -5px;
}
#ptw-container.ptw-horizontal-search #ap_booker_multipleDestinations .ptw-multiflight-header,
#ptw-container.ptw-horizontal-search #ap_booker_multipleDestinations .ptw-field-destination,
#ptw-container.ptw-horizontal-search #ap_booker_multipleDestinations .ptw-field-origin,
#ptw-container.ptw-horizontal-search #ap_booker_multipleDestinations .ptw-field-dates {
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
}
#ptw-container.ptw-horizontal-search #ap_booker_multipleDestinations .ptw-multiflight-header {
  width: 15%;
}
#ptw-container.ptw-horizontal-search #ap_booker_multipleDestinations .ptw-field-destination,
#ptw-container.ptw-horizontal-search #ap_booker_multipleDestinations .ptw-field-origin {
  width: 30%;
}
#ptw-container.ptw-horizontal-search #ap_booker_multipleDestinations .ptw-field-dates {
  width: 25%;
}
#ptw-container.ptw-horizontal-search #ap_booker_Transfer .ptw-fields-container > .ptw-field {
  width: 7%;
  min-width: 110px;
}
#ptw-container.ptw-horizontal-search #ap_booker_Transfer .ptw-fields-container > .ptw-field-transferAirport,
#ptw-container.ptw-horizontal-search #ap_booker_Transfer .ptw-fields-container > .ptw-field-transferHotel,
#ptw-container.ptw-horizontal-search #ap_booker_Transfer .ptw-fields-container > .ptw-field-dates,
#ptw-container.ptw-horizontal-search #ap_booker_Transfer .ptw-fields-container > .ptw-field-bus-passengers {
  width: 17%;
}
#ptw-container.ptw-horizontal-search #ap_booker_Transfer .ptw-fields-container > .ptw-field-dates {
  min-width: 170px;
}
#ptw-container.ptw-horizontal-search #ap_booker_Transfer .ptw-fields-container > .ptw-field-bus-passengers {
  min-width: 190px;
  margin-bottom: 3px;
}
#ptw-container.ptw-horizontal-search #ap_booker_Transfer .ptw-fields-container > .ptw-field-bus-passengers .ptw-field {
  width: 33%;
  margin-right: 0;
}
#ptw-container.ptw-horizontal-search #ap_booker_Transfer .ptw-fields-container > .ptw-buttons {
  min-width: 130px;
}
#ptw-container.ptw-horizontal-search #ap_booker_Cruise .ptw-fields-container .ptw-field-dates {
  width: auto;
  min-width: 200px;
}
#ptw-container.ptw-horizontal-search #ap_booker_carTrip .ptw-fields-container .ptw-field-selectEndOffice {
  margin: 7px 0;
  max-width: 200px;
  min-width: 180px;
  padding-left: 5px;
  padding-right: 5px;
}
#ptw-container.ptw-horizontal-search
  #ap_booker_carTrip
  .ptw-fields-container
  .ptw-field-selectEndOffice
  .ptw-field-radio {
  padding: 0.6em 0;
  white-space: nowrap;
}
#ptw-container.ptw-horizontal-search
  #ap_booker_carTrip
  .ptw-fields-container
  .ptw-field-selectEndOffice
  .ptw-field-radio
  label
  + .ap_carType {
  margin-left: 1em;
}
#ptw-container.ptw-horizontal-search #ap_booker_carTrip .ptw-fields-container .ptw-field-startOffice {
  max-width: 200px;
  margin-bottom: 0;
}
#ptw-container.ptw-horizontal-search #ap_booker_carTrip .ptw-fields-container .ptw-field-endOffice {
  padding-top: 15px;
  width: auto;
  min-width: 0;
}
#ptw-container.ptw-horizontal-search #ap_booker_carTrip .ptw-fields-container .ptw-field-dates {
  min-width: 340px;
}
#ptw-container.ptw-horizontal-search #ap_booker_carTrip .ptw-fields-container .ptw-field-date {
  min-width: 170px;
}
#ptw-container.ptw-horizontal-search #ap_booker_carTrip .ptw-fields-container .ptw-car-date,
#ptw-container.ptw-horizontal-search #ap_booker_carTrip .ptw-fields-container .ptw-car-time {
  width: 50%;
}
#ptw-container.ptw-horizontal-search #ap_booker_carTrip .ptw-fields-container .ptw-car-time {
  padding-top: 1.2em;
}
#ptw-container .ptw-btn,
#ptw-container .ptw-submit-btn,
.btnOfferts a,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn {
  display: inline-block;
  text-decoration: none;
  *display: inline;
  padding: 4px 12px;
  margin-bottom: 0;
  *margin-left: 0.3em;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  *zoom: 1;
}
#ptw-container .ptw-btn:active,
#ptw-container .ptw-submit-btn:active,
.btnOfferts a:active,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn:active,
#ptw-container .active.ptw-btn,
#ptw-container .active.ptw-submit-btn,
.btnOfferts a.active,
#ptw-offers .ptw-offer-item > a .active.ptw-submit-btn,
#ptw-container .ptw-btn:hover,
#ptw-container .ptw-submit-btn:hover,
.btnOfferts a:hover,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn:hover,
#ptw-container .ptw-btn:focus,
#ptw-container .ptw-submit-btn:focus,
.btnOfferts a:focus,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn:focus {
  text-decoration: none;
  background-position: 0 15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
#ptw-container .disabled.ptw-btn,
#ptw-container .disabled.ptw-submit-btn,
.btnOfferts a.disabled,
#ptw-offers .ptw-offer-item > a .disabled.ptw-submit-btn,
#ptw-container [disabled].ptw-btn,
#ptw-container [disabled].ptw-submit-btn,
.btnOfferts a[disabled],
#ptw-offers .ptw-offer-item > a [disabled].ptw-submit-btn,
#ptw-container .disabled.ptw-btn:hover,
#ptw-container .disabled.ptw-submit-btn:hover,
.btnOfferts a.disabled:hover,
#ptw-offers .ptw-offer-item > a .disabled.ptw-submit-btn:hover,
#ptw-container [disabled].ptw-btn:hover,
#ptw-container [disabled].ptw-submit-btn:hover,
.btnOfferts a[disabled]:hover,
#ptw-offers .ptw-offer-item > a [disabled].ptw-submit-btn:hover {
  color: #888;
  background: #e6e6e6;
  *background: #d9d9d9;
  border: 1px solid #ccc;
  cursor: default;
  text-shadow: none;
}
#ptw-container .ptw-btn {
  color: #333;
  text-shadow: 0 1px 0 #f8f8f8;
  background: #e6e6e6;
  border: 1px solid #e6e6e6;
  border-bottom-color: silver;
}
#ptw-container .ptw-btn:active,
#ptw-container .active.ptw-btn,
#ptw-container .ptw-btn:hover,
#ptw-container .ptw-btn:focus {
  color: #333;
  background-color: #f8f8f8;
}
#ptw-container .ptw-submit-btn,
.btnOfferts a,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn {
  color: #fff;
  text-shadow: 0 -1px 0 #136701;
  background: #1a8a01;
  border: 1px solid #1a8a01;
  border-bottom-color: #0c3e00;
}
#ptw-container .ptw-submit-btn:active,
.btnOfferts a:active,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn:active,
#ptw-container .active.ptw-submit-btn,
.btnOfferts a.active,
#ptw-offers .ptw-offer-item > a .active.ptw-submit-btn,
#ptw-container .ptw-submit-btn:hover,
.btnOfferts a:hover,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn:hover,
#ptw-container .ptw-submit-btn:focus,
.btnOfferts a:focus,
#ptw-offers .ptw-offer-item > a .ptw-submit-btn:focus {
  color: #fff;
  background-color: #21ad01;
}
#ptw-container .ptw-buttons {
  margin-top: 10px;
  padding-top: 10px;
}
#ptw-container .ptw-month-cal {
  margin: 0;
  width: 10em;
  background: #fff;
  border: 1px solid #ddd;
}
#ptw-container .ptw-month-cal li {
  padding: 0;
  margin: 1%;
  float: left;
  width: 31%;
  text-align: center;
}
#ptw-container .ptw-month-cal a {
  padding: 0.25em 0;
  line-height: 150%;
  text-decoration: none;
  color: #555;
  background: #eee;
  display: block;
  text-align: center;
}
#ptw-container .ptw-month-cal a.previous,
#ptw-container .ptw-month-cal a.previous:hover {
  opacity: 0.5;
  filter: Alpha(Opacity=50);
  color: #555;
  background: #eee;
  cursor: default !important;
}
#ptw-container .ptw-month-cal a:hover,
#ptw-container .ptw-month-cal a.ptw-ap_calPrev:hover,
#ptw-container .ptw-month-cal a.ptw-ap_calNext:hover {
  background: #314c67;
  color: #fff;
}
#ptw-container .ptw-month-cal a.ptw-selected,
#ptw-container .ptw-month-cal a.selected {
  background: #41668a;
  color: #fff;
}
#ptw-container .ptw-month-cal li.ptw-cal-title {
  padding: 0.2em 0;
  line-height: 1.8em;
  margin: 0 0 2% 0;
  text-align: center;
  font-weight: bold;
  position: relative;
  width: 100%;
  float: none;
  border-bottom: 1px solid #ddd;
  background: #ededed;
  color: #333;
}
#ptw-container .ptw-month-cal a.ptw-ap_calPrev,
#ptw-container .ptw-month-cal a.ptw-ap_calNext {
  padding: 0;
  margin: 0;
  background: #999;
  color: #fff;
  position: absolute;
  top: 3px;
  width: 1.6em;
  height: 1.6em;
  border-radius: 3px;
  cursor: pointer;
}
#ptw-container .ptw-month-cal a.ptw-ap_calPrev {
  left: 3px;
}
#ptw-container .ptw-month-cal a.ptw-ap_calNext {
  right: 3px;
}
#ptw-container .ptw-month-cal a.ptw-ap_calPrev,
#ptw-container .ptw-month-cal a.ptw-ap_calNext {
  text-indent: -9999em;
}
a.navLeft.ap_calPrev.ptw-ap_calPrev:after,
a.navRight.ap_calNext.ptw-ap_calNext:after {
  content: " ";
  display: block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -4px;
}
a.navLeft.ap_calPrev.ptw-ap_calPrev:after {
  border-right: 5px solid #fff;
  margin-left: -3px;
}
a.navRight.ap_calNext.ptw-ap_calNext:after {
  border-left: 5px solid #fff;
  margin-left: -1px;
}
.dSprite {
  background: url(../images/sprite-destinations.jpg?v=3) no-repeat;
  width: 110px;
  height: 70px;
  display: block;
  text-indent: -999em;
}
.topDestinations .huatulco,
.picture-list .huatulco {
  background-position: 0 0;
}
.topDestinations .veracruz,
.picture-list .veracruz {
  background-position: -110px 0;
}
.topDestinations .pCarmen,
.picture-list .pCarmen {
  background-position: -330px 0;
}
.topDestinations .gto,
.picture-list .gto {
  background-position: -220px 0;
}
.topDestinations .manzanillo,
.picture-list .manzanillo {
  background-position: 0 -70px;
}
.topDestinations .chiapas,
.picture-list .chiapas {
  background-position: -110px -70px;
}
.topDestinations .orl,
.topDestinations .mco,
.picture-list .orl,
.picture-list .mco {
  background-position: 0 -140px;
}
.topDestinations .mia,
.picture-list .mia {
  background-position: -220px -140px;
}
.topDestinations .las,
.picture-list .las {
  background-position: -330px -140px;
}
.topDestinations .sfo,
.picture-list .sfo {
  background-position: -110px -140px;
}
.topDestinations .bas,
.picture-list .bas {
  background-position: -110px -280px;
}
.topDestinations .mza,
.picture-list .mza {
  background-position: -330px -350px;
}
.topDestinations .igu,
.picture-list .igu {
  background-position: -220px -420px;
}
.topDestinations .mlm,
.topDestinations .MLM,
.picture-list .mlm,
.picture-list .MLM {
  background-position: -110px -1120px;
}
.topDestinations .gdl,
.topDestinations .GDL,
.picture-list .gdl,
.picture-list .GDL {
  background-position: -330px -770px;
}
.topDestinations .pbc,
.topDestinations .PBC,
.picture-list .pbc,
.picture-list .PBC {
  background-position: -330px -630px;
}
.topDestinations .mry,
.topDestinations .MTY,
.topDestinations .mty,
.picture-list .mry,
.picture-list .MTY,
.picture-list .mty {
  background-position: -220px -630px;
}
.topDestinations .cba,
.picture-list .cba {
  background-position: 0 -350px;
}
.topDestinations .caba,
.picture-list .caba {
  background-position: -330px -420px;
}
.topDestinations .cbac,
.picture-list .cbac {
  background-position: -220px -280px;
}
.topDestinations .scb,
.picture-list .scb {
  background-position: -220px -350px;
}
.topDestinations .pilar,
.picture-list .pilar {
  background-position: -330px -280px;
}
.topDestinations .pcana,
.picture-list .pcana {
  background-position: 0 -420px;
}
.topDestinations .pplata,
.picture-list .pplata {
  background-position: -110px -420px;
}
.topDestinations .cun,
.topDestinations .CUN,
.picture-list .cun,
.picture-list .CUN {
  background-position: -330px -70px;
}
.topDestinations .aca,
.topDestinations .ACA,
.picture-list .aca,
.picture-list .ACA {
  background-position: -330px -560px;
}
.topDestinations .pvr,
.topDestinations .PVR,
.picture-list .pvr,
.picture-list .PVR {
  background-position: 0 -560px;
}
.topDestinations .zlo,
.picture-list .zlo {
  background-position: -220px -70px;
}
.topDestinations .mex,
.topDestinations .MEX,
.picture-list .mex,
.picture-list .MEX {
  background-position: -110px -560px;
}
.topDestinations .sjd,
.topDestinations .SJD,
.picture-list .sjd,
.picture-list .SJD {
  background-position: -220px -560px;
}
.topDestinations .jam,
.picture-list .jam {
  background-position: -110px -490px;
}
.topDestinations .esm,
.picture-list .esm {
  background-position: 0 -630px;
}
.topDestinations .lvg,
.picture-list .lvg {
  background-position: -220px -210px;
}
.topDestinations .nyc,
.topDestinations .jfk,
.picture-list .nyc,
.picture-list .jfk {
  background-position: -330px -210px;
}
.topDestinations .bla,
.picture-list .bla {
  background-position: -110px -630px;
}
.topDestinations .hav,
.picture-list .hav {
  background-position: 0 -700px;
}
.topDestinations .var,
.picture-list .var {
  background-position: -110px -700px;
}
.topDestinations .cal,
.topDestinations .CLO,
.picture-list .cal,
.picture-list .CLO {
  background-position: -220px -700px;
}
.topDestinations .ros,
.picture-list .ros {
  background-position: -330px -700px;
}
.topDestinations .sanAnd,
.topDestinations .ADZ,
.picture-list .sanAnd,
.picture-list .ADZ {
  background-position: -220px -840px;
}
.topDestinations .staMta,
.topDestinations .SMR,
.picture-list .staMta,
.picture-list .SMR {
  background-position: -330px -840px;
}
.topDestinations .cart,
.topDestinations .CTG,
.picture-list .cart,
.picture-list .CTG {
  background-position: -110px -840px;
}
.topDestinations .bog,
.topDestinations .BOG,
.picture-list .bog,
.picture-list .BOG {
  background-position: -110px -490px;
}
.topDestinations .med,
.topDestinations .EOH,
.picture-list .med,
.picture-list .EOH {
  background-position: 0 -840px;
}
.topDestinations .barr,
.picture-list .barr {
  background-position: 0 -490px;
}
.topDestinations .cali,
.picture-list .cali {
  background-position: -330px -490px;
}
.topDestinations .panam,
.picture-list .panam {
  background-position: -220px -980px;
}
.topDestinations .boquet,
.picture-list .boquet {
  background-position: -329px -980px;
}
.topDestinations .sdc,
.picture-list .sdc {
  background-position: 0px -1049px;
}
.topDestinations .haba,
.picture-list .haba {
  background-position: -110px -1049px;
}
.topDestinations .varad,
.picture-list .varad {
  background-position: -220px -1049px;
}
.topDestinations .manizal,
.picture-list .manizal {
  background-position: -330px -1049px;
}
.topDestinations .bcmanga,
.picture-list .bcmanga {
  background-position: -0px -1121px;
}
.topDestinations .fll,
.picture-list .fll {
  background-position: -220px -1121px;
}
.topDestinations .snu,
.picture-list .snu {
  background-position: -330px -1121px;
}
.topDestinations .sdc,
.topDestinations .std,
.picture-list .sdc,
.picture-list .std {
  background-position: 0 -1200px;
}
.topDestinations .sti,
.picture-list .sti {
  background-position: -110px -1200px;
}
.topDestinations .adz,
.picture-list .adz {
  background-position: -220px -1200px;
}
.topDestinations .dav,
.picture-list .dav {
  background-position: -330px -1200px;
}
.topDestinations .chi,
.picture-list .chi {
  background-position: 0 -1270px;
}
.topDestinations .pty,
.topDestinations .PTY,
.picture-list .pty,
.picture-list .PTY {
  background-position: -110px -1270px;
}
.topDestinations .vra,
.picture-list .vra {
  background-position: -220px -1270px;
}
.topDestinations .vsa,
.picture-list .vsa {
  background-position: -330px -1270px;
}
.topDestinations .pbp,
.picture-list .pbp {
  background-position: 0px -1340px;
}
.topDestinations .col,
.picture-list .col {
  background-position: -110px -1340px;
}
.topDestinations .iah,
.picture-list .iah {
  background-position: -220px -1340px;
}
.topDestinations .dfw,
.picture-list .dfw {
  background-position: -330px -1340px;
}
.topDestinations .mga,
.picture-list .mga {
  background-position: 0px -1410px;
}
.topDestinations .gra,
.picture-list .gra {
  background-position: -110px -1410px;
}
.topDestinations .vlc,
.picture-list .vlc {
  background-position: -220px -1410px;
}
.topDestinations .hog,
.picture-list .hog {
  background-position: -330px -1410px;
}
.topDestinations .slw,
.picture-list .slw {
  background-position: 0px -1480px;
}
.topDestinations .bjx,
.picture-list .bjx {
  background-position: -110px -1480px;
}
.topDestinations .cuu,
.picture-list .cuu {
  background-position: -220px -1480px;
}
.topDestinations .tij,
.picture-list .tij {
  background-position: -330px -1480px;
}
.topDestinations .qro,
.picture-list .qro {
  background-position: 0px -1550px;
}
#ptw-offers .ptw-offer-item > a .ptw-offer-title {
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #06c;
}
#ptw-offers .ptw-offer-item > a .ptw-price {
  font-size: 1.8em;
  margin: 0.2em 0;
}
#ptw-offers .ptw-offer-item > a .ptw-price-note,
#ptw-offers .ptw-offer-item > a .ptw-currency {
  color: gray;
}
#ptw-offers .ptw-offer-item > a .ptw-offer-info,
#ptw-offers .ptw-offer-item > a .ptw-offer-price {
  float: left;
  width: 50%;
}
#ptw-offers .ptw-offer-item > a .ptw-offer-info > span,
#ptw-offers .ptw-offer-item > a .ptw-offer-info > strong,
#ptw-offers .ptw-offer-item > a .ptw-offer-price > span,
#ptw-offers .ptw-offer-item > a .ptw-offer-price > strong {
  display: block;
}
#ptw-offers .ptw-offer-item > a .ptw-offer-info .ptw-offer-title {
  font-weight: bold;
  margin-top: 0.5em;
}
#ptw-offers .ptw-offer-item > a .ptw-offer-price {
  text-align: right;
  width: 47%;
  margin-left: 3%;
}
#ptw-offers .ptw-offer-item > a .ptw-offer-btn {
  clear: both;
  margin-top: 1em;
}
#ptw-package-offers {
  padding: 0;
  margin: 0;
}
#ptw-package-offers *,
#ptw-package-offers *:after,
#ptw-package-offers *:before {
  box-sizing: border-box;
}
.contentOffers {
  background: #fff;
  color: #333;
  font-size: 13px;
  text-align: left;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  overflow: hidden;
  margin: 0 -5px;
}
.contentOffers .contentOffers {
  margin: 0;
}
.contentOffers .boxOffers {
  border: 1px solid #ddd;
  float: left;
  margin: 5px;
  padding: 10px;
  max-width: 285px;
  overflow: hidden;
}
.contentOffers .boxOffers h5 {
  padding: 0px;
  margin: 0px;
  font-weight: normal;
}
.contentOffers .boxOffers p {
  margin: 5px 0 0 2px;
  padding: 0px;
  line-height: 120%;
  font-size: 12px;
}
.contentOffers .colI {
  float: left;
  width: 55%;
}
.contentOffers .colD {
  float: right;
  padding-left: 10px;
  max-width: 45%;
}
.contentOffers .colD h1 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 0px !important;
  padding: 0px !important;
}
.contentOffers .colD h1 a {
  text-decoration: none;
  color: #06c;
}
.contentOffers .colD h4 {
  margin: 0;
  padding: 0;
  text-align: right;
  font-weight: normal;
  font-size: 14px;
}
.contentOffers .colI h4 {
  margin: 0px 0px 10px 0px;
  font-weight: normal;
}
.contentOffers .btnOfferts {
  padding-top: 0.5em;
  margin-top: 0.5em;
  clear: both;
  text-align: right;
}
.contentOffers .signo {
  font-size: 20px;
  font-weight: normal;
}
#ptw-travel-styles .ptw-style-item .ptw-style-title {
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #06c;
}
#ptw-travel-styles .ptw-style-item .ptw-style-note {
  color: gray;
  margin-bottom: 1em;
}
#ptw-travel-styles .ptw-style-item.ptw-style-morelink .ptw-style-note {
  margin-bottom: 0;
}
#hotelOffers {
  background: #fff;
  color: #333;
  font-size: 13px;
  text-align: left;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  padding: 0;
  margin: 0;
}
#hotelOffers * {
  margin: 0;
  padding: 0;
  line-height: 100%;
}
#hotelOffers h1 {
  font-weight: normal;
  font-size: 150%;
  color: #333;
}
#hotelOffers .row {
  margin-right: -15px;
  margin-left: -15px;
}
#hotelOffers .col {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 50%;
}
#hotelOffers table.list {
  width: 100%;
  margin: 5px 0;
}
#hotelOffers table.list th {
  text-align: right;
  padding: 0.4em 0.6em;
  border-bottom: 2px solid #333;
  margin-top: 10px;
}
#hotelOffers table.list th.alt {
  text-align: left;
}
#hotelOffers table.list td {
  font-weight: bold;
  text-align: right;
}
#hotelOffers table.list td.alt {
  font-weight: normal;
  text-align: left;
}
#hotelOffers table.list td a {
  display: block;
  padding: 0.4em 0.6em;
  border-bottom: 1px dotted #ddd;
}
#hotelOffers p.note {
  font-size: 85%;
  color: gray;
  padding: 0.5em 0;
}
#hotelOffers h1 .small {
  font-size: 85%;
  display: block;
}
#hotelOffers .picture-list {
  margin: 5px 0;
}
#hotelOffers .picture-list li {
  margin-bottom: 10px;
  list-style: none;
}
#hotelOffers .picture-list a {
  text-decoration: none;
  color: #333;
}
#hotelOffers .picture-list .dSprite {
  float: left;
  width: 50px;
  height: 50px;
}
#hotelOffers .picture-list .destinationName,
#hotelOffers .picture-list .priceHotels {
  margin-left: 60px;
  display: block;
}
#hotelOffers .picture-list .destinationName {
  color: #06c;
  margin-top: 5px;
  margin-bottom: 3px;
}
#hotelOffers .picture-list .priceHotels {
  font-weight: bold;
  color: gray;
  font-size: 85%;
}
#topDestinations {
  background: #fff;
  color: #333;
  font-size: 13px;
  text-align: left;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  padding: 0;
  margin: 0;
}
#topDestinations * {
  margin: 0;
  padding: 0;
  line-height: 100%;
}
#topDestinations h1 {
  font-weight: normal;
  font-size: 150%;
  color: #333;
}
#topDestinations .nav {
  width: 100%;
  border-bottom: 3px solid #333;
  margin: 10px 0;
}
#topDestinations .nav li,
#topDestinations .nav a {
  float: left;
  display: block;
}
#topDestinations .nav a {
  padding: 5px;
  margin-right: 2px;
  text-decoration: none;
  border-bottom: 3px solid transparent;
}
#topDestinations .nav .current a,
#topDestinations .nav .current a:hover {
  font-weight: bold;
  cursor: text;
}
#topDestinations .topDestinations ul,
#topDestinations .topDestinations li {
  list-style: none;
}
#topDestinations .featuredDestinations {
  margin-top: 5px;
}
#topDestinations .featuredDestinations li {
  float: left;
  margin: 0 15px 8px 0;
  padding: 0 0 9px 0;
  width: 125px;
  z-index: -1;
}
#topDestinations .featuredDestinations a {
  display: block;
  border: 1px solid #ddd;
  padding: 6px;
  text-decoration: none;
}
#topDestinations .featuredDestinations img {
  border: 0;
}
#topDestinations .featuredDestinations strong {
  display: block;
  width: 111px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5em 0 0.3em 0;
}
#topDestinations .featuredDestinations span {
  font-size: 12px;
}
#topDestinations .featuredDestinations span.priceHotels {
  display: block;
  font-weight: bold;
  margin-top: 0.5em;
}
#ptw-searchList *,
#ptw-searchList *:after,
#ptw-searchList *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#ptw-searchList li {
  position: relative;
}
#ptw-searchList li:hover {
  background: #ffe;
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.1);
}
#ptw-searchList li:hover a.ptw-searchLink {
  zoom: 1;
  filter: alpha(opacity=70);
  opacity: 0.7;
}
#ptw-searchList li a.ptw-searchLink:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}
#ptw-searchList a.ptw-searchLink {
  border-bottom: 1px solid #eee;
  margin-top: -1px;
  overflow: hidden;
  display: block;
  padding: 7px 5px 5px 5px;
  color: #333;
  font-size: 12px;
  line-height: 1.3em;
}
#ptw-searchList .ptw-itemInfo,
#ptw-searchList .ptw-itemPrice {
  float: left;
}
#ptw-searchList .ptw-itemInfo {
  width: 70%;
  padding-left: 7px;
}
#ptw-searchList .ptw-itemPrice {
  width: 30%;
  text-align: right;
  font-size: 12px;
  padding-right: 20px;
}
#ptw-searchList .ptw-itemPrice strong {
  display: block;
  font-size: 15px;
  margin: 3px 0 5px 0;
}
#ptw-searchList .ptw-itemType {
  display: block;
  margin-bottom: 5px;
  line-height: 18px;
}
#ptw-searchList .ptw-itemName {
  font-size: 14px;
  color: #06c;
}
#ptw-searchList .ptw-itemDetails {
  display: block;
  line-height: 1.4em;
  margin-top: 3px;
}
#ptw-searchList .ptw-itemType,
#ptw-searchList .ptw-itemDetails,
#ptw-searchList .ptw-quoteDate {
  color: gray;
}
#ptw-searchList a.ptw-removeLink {
  padding: 7px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  zoom: 1;
  filter: alpha(opacity=70);
  opacity: 0.7;
}
#ptw-searchList a.ptw-removeLink:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}
#ptw-searchList .ptw-notAva {
  display: block;
  margin-bottom: 3px;
  color: gray;
}
#ptw-searchList a.ptw-historyLink {
  display: block;
  background: #f6f6f6;
  border-top: 1px solid #eee;
  margin: 0 -6px -1px -6px;
  padding: 0.5em 1em;
  -moz-border-radius: 0 0 3px 3px;
  -webkit-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}
#ptw-searchList a.ptw-historyLink:hover {
  background: #f0f7ff;
}
#ptw-destination-guides .ptw-dguides-item {
  margin-bottom: 10px;
}
#ptw-destination-guides .ptw-dguides-item .ptw-dguides-pic,
#ptw-destination-guides .ptw-dguides-item .ptw-dguides-item-content {
  float: left;
}
#ptw-destination-guides .ptw-dguides-item .ptw-dguides-pic {
  width: 25%;
  max-width: 180px;
}
#ptw-destination-guides .ptw-dguides-item .ptw-dguides-item-content {
  width: 75%;
  max-width: 780px;
  padding-left: 10px;
}
#ptw-destination-guides .ptw-dguides-item .ptw-dguides-item-content h3 {
  margin-bottom: 10px;
}
#ptw-slider {
  width: 100%;
  margin: 0 auto;
}
#ptw-slider > ul {
  padding: 0;
  margin: 0;
}
#ptw-slider li > div,
#ptw-slider li > a {
  margin: 0 auto;
  position: relative;
  display: block;
  transition: none !important;
}
#ptw-slider div.preload {
  background: url("//3.cdnpt.com/affiliates/general/images/Widget-v2-loader.gif") no-repeat center 25%;
}
#ptw-slider-pager {
  margin: 0 auto;
  text-align: center;
  display: table;
  font-size: 0;
  position: relative;
  z-index: 10;
  margin-top: -30px;
}
#ptw-slider-pager a {
  display: inline-block;
  width: 10px;
  height: 10px;
  font-size: 0;
  margin: 2px 6px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.6);
  background: #999;
  border: 1px solid #666;
  opacity: 0.7;
}
#ptw-slider-pager a:hover {
  opacity: 1;
}
#ptw-slider-pager a.active {
  background-color: #41668a;
  box-shadow:
    inset 0 1px 3px -1px rgba(255, 255, 255, 0.5),
    0 1px 1px rgba(0, 0, 0, 0.5);
  opacity: 1;
}
#ptw-slider-prev,
#ptw-slider-next {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 60px;
  display: inline-block;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: width 0.5s;
  line-height: 50px;
  color: white;
  overflow: hidden;
  font-size: 13px;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
#ptw-slider-prev:before,
#ptw-slider-prev:before,
#ptw-slider-next:before,
#ptw-slider-next:before {
  width: 0;
  height: 0;
  margin-top: 20px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  content: " ";
  display: block;
}
#ptw-slider-prev {
  border-radius: 0 4px 4px 0;
  left: 0;
}
#ptw-slider-prev:before {
  border-right: 10px solid #fff;
  margin-left: 17px;
  float: left;
}
#ptw-slider-next {
  border-radius: 4px 0 0 4px;
  right: 0;
}
#ptw-slider-next:before {
  border-left: 10px solid #fff;
  margin-right: 17px;
  float: right;
}
#ptw-slider-prev:hover,
#ptw-slider-next:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}
#ptw-slider-prev div {
  margin-left: 60px;
  white-space: nowrap;
}
#ptw-slider-next div {
  float: right;
  margin-right: 60px;
  white-space: nowrap;
}
#ptw-slider-pause-play {
  display: none;
}
#ptw-slider > ul {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  backface-visibility: hidden;
  perspective: 1000px;
  -webkit-tap-highlight-color: transparent;
}
#ptw-slider-pager,
#ptw-slider-prev,
#ptw-slider-next,
#ptw-slider-pause-play {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
#ptw-slider {
  position: relative;
  overflow: hidden;
  padding: 0;
}
#ptw-slider > ul {
  overflow: hidden;
  position: relative;
  list-style: none;
  margin: 0;
}
#ptw-slider > ul > li {
  float: left;
  width: 100%;
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
}
#ptw-slideshow #slides {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#ptw-slideshow #slides .slides_container {
  overflow: hidden;
  position: relative;
  display: none;
}
#ptw-slideshow #slides .slides_container div.slide {
  display: block;
}
#ptw-slideshow #slides .slides_container a,
#ptw-slideshow #slides .slides_container a img {
  border: none;
}
#ptw-slideshow #slides .next,
#ptw-slideshow #slides .prev {
  display: none;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  z-index: 9;
  cursor: pointer;
  text-indent: -9999px;
  border: 0;
  background-color: #000;
  filter: alpha(opacity=50);
  opacity: 0.5;
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
#ptw-slideshow #slides .next:hover,
#ptw-slideshow #slides .prev:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}
#ptw-slideshow #slides .next {
  right: 20px;
}
#ptw-slideshow #slides .prev {
  left: 20px;
}
#ptw-slideshow #slides:hover .next,
#ptw-slideshow #slides:hover .prev {
  display: block;
}
#ptw-slideshow #slides .next:before,
#ptw-slideshow #slides .prev:before {
  width: 0;
  height: 0;
  margin-top: 15px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  content: " ";
  display: block;
}
#ptw-slideshow #slides .next:before {
  border-left: 10px solid #fff;
  margin-left: 22px;
}
#ptw-slideshow #slides .prev:before {
  border-right: 10px solid #fff;
  margin-left: 18px;
}
#ptw-slideshow #slides .pagination {
  margin: 10px;
  padding-left: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9;
}
#ptw-slideshow #slides .pagination li {
  float: left;
  margin: 0 1px;
  list-style: none;
}
#ptw-slideshow #slides .pagination li a {
  display: block;
  width: 8px;
  height: 0;
  padding-top: 8px;
  float: left;
  margin-left: 4px;
  overflow: hidden;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  background: #41668a;
  border: 1px solid #314c67;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
#ptw-slideshow #slides .pagination li a:hover {
  filter: alpha(opacity=80);
  opacity: 0.8;
}
#ptw-slideshow #slides .pagination li.current a,
#ptw-slideshow #slides .pagination li.current a:hover {
  filter: alpha(opacity=100);
  opacity: 1;
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
}
#ptw-slideshow #slides .caption {
  z-index: 500;
  position: absolute;
  bottom: -35px;
  left: 0;
  height: 30px;
  padding: 5px 20px 0 20px;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  width: 540px;
  font-size: 1.3em;
  line-height: 1.33;
  color: #fff;
  text-shadow: none;
}
.ninja-slider {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
}
.ninja-slider.fullscreen {
  background: #000;
}
.ninja-slider .fs-icon {
  top: 6px;
  right: 6px;
  width: 60px;
  height: 26px;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
  color: white;
  text-align: center;
  font: bold 11px/26px arial;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  opacity: 0;
  -webkit-transition: opacity 0.8s;
  transition: opacity 0.8s;
}
.ninja-slider .slider-inner:hover .fs-icon,
.ninja-slider.fullscreen .fs-icon {
  opacity: 1;
}
.ninja-slider .fs-icon::before {
  content: "EXPAND";
  display: block;
}
.ninja-slider.fullscreen .fs-icon::before {
  content: "CLOSE";
}
.ninja-slider .slider-inner {
  max-width: 700px;
  margin: 0 auto;
  font-size: 0px;
  position: relative;
  box-sizing: border-box;
}
.ninja-slider.fullscreen .slider-inner {
  width: 95%;
  max-width: 900px;
}
.ninja-slider ul {
  position: relative;
  list-style: none;
  padding: 0;
  box-sizing: border-box;
}
.ninja-slider li {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  font-size: 12px;
  list-style: none;
  margin: 0;
  padding: 0;
  opacity: 0;
  overflow: hidden;
  box-sizing: border-box;
}
.ninja-slider li.ns-show {
  opacity: 1;
}
.ninja-slider .ns-img {
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  cursor: default;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
.ninja-slider .caption {
  padding: 20px 40px;
  margin: auto;
  left: 0;
  right: 0;
  top: auto;
  bottom: 0px;
  width: auto;
  text-align: right;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.2);
  background: none;
  position: absolute;
  box-sizing: border-box;
}
.ninja-slider li .cap1 {
  color: rgba(255, 255, 255, 0.5);
  font: bold 32px arial;
  text-align: center;
  opacity: 0;
  bottom: 40%;
  -webkit-transition:
    bottom 1.3s,
    opacity 1s;
  transition:
    bottom 1.3s,
    opacity 1s;
}
.ninja-slider li[class*="-"] .cap1 {
  opacity: 1;
  bottom: 20%;
}
.ninja-slider li .cap2 {
  color: rgba(255, 162, 0, 0.5);
  font-size: 23px;
  bottom: 15%;
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}
.ninja-slider li[class*="-s"] .cap2 {
  bottom: 10%;
}
.ninja-slider-pause-play {
  display: none;
}
.controls-prev,
.controls-next {
  position: absolute;
  display: inline-block;
  width: 42px;
  height: 56px;
  line-height: 56px;
  top: 50%;
  margin-top: -51px;
  background-color: rgba(0, 0, 0, 0.4);
  background-color: #ccc9;
  backface-visibility: hidden;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 2px;
  z-index: 10;
  opacity: 0.3;
  font-size: 13px;
  cursor: pointer;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
}
.slider-inner:hover .controls-prev,
.slider-inner:hover .controls-next {
  opacity: 1;
}
.controls-prev {
  left: 0px;
}
.controls-next {
  right: 0px;
}
.controls-prev::before,
.controls-next::before {
  position: absolute;
  top: 17px;
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border-left: 2px solid white;
  border-top: 2px solid white;
  backface-visibility: hidden;
}
.controls-prev::before {
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 4px;
}
.controls-next::before {
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  left: 4px;
}
.controls-prev div,
.controls-next div {
  display: none;
}
.controls-pager {
  padding-top: 10px;
  margin: 0 auto;
  text-align: center;
  display: table;
  font-size: 0;
}
.controls-pager a {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ccc;
  font-size: 0;
  margin: 2px 6px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: inset 0 1px 3px #666666;
}
.controls-pager a:hover {
  opacity: 0.6;
}
.controls-pager a.active {
  background-color: #1293dc;
  box-shadow:
    inset 0 1px 3px -1px #28b4ea,
    0 1px 1px rgba(0, 0, 0, 0.5);
}
.controls-pager,
.controls-prev,
.controls-next,
.ninja-slider-pause-play {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
div.ninja-slider div.fs-icon {
  z-index: 10;
}
@media only screen and (max-width: 600px) {
  .controls-prev,
  .controls-next,
  .controls-pager {
    display: none;
  }
  .ninja-slider li .cap1 {
    font-size: 20px;
  }
}
.slideshow .ninja-slider {
  padding: 0;
}
.slideshow .ninja-slider ul {
  margin-bottom: 0;
  margin-top: 0;
}
.slideshow .ninja-slider .fs-icon {
  background-color: rgba(255, 255, 255, 0.7);
  width: auto;
  height: auto;
}
.slideshow .ninja-slider .fs-icon::before {
  content: "";
  display: block;
  width: 32px;
  height: 32px;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAB4ElEQVR4Xu3a0VHDMBAE0EtHdEApQAWUBK1QGcwxUcYEW9JJu5lbjf2TH6xon5SLzuFiea9XM/tgT+/CfoPJ8ekI2QHcj4qgAEBFUAGgISgBUBDUAOAIigBQBFUAGIIyAARBHcBPin5OGL6UAabDu5oqACS8KgAsvCIANLwaADy8EkA0/JuZPZnZe+vrQaEIjoT/vAb315caQnaAmfAldxUhMwAifBMhKwAyfBUhIwAj/CFCRoDIM0Cv9qXgtQr+LkJGgN4ubyT8P4SsAC2EmfB/EDIDHCEgwt8QsgPcIyDD/yIoABQEf40WvGZhVAFoBhn9gxNgVG6V+84dsMpKjuY4d8Co3Cr3nTuAtJLe0n4xDi7o+TJ2wLafhx9dGQDef6OOmHsPM1Ij+A74NjPEJGtPchDjoxf/1gw5gF8zk+x5jDUzPiV86QYLwChCT/gSIB1C+QhshSOTjIQfBaat/t4OiKyUfPgaQGu1lgjfAjhCWCZ8D8A9wlLhewEKwnPwH5IixZRa6GqD730LICYjET6yAyIoMuEZAFLh0QBy4ZEAkuFRALLhT4Drb4PbbjBS7UcbqNH3oNyHPAdIfhSQAK0GirKCs4OiAeQQGABSCCwAGQQmgAQCGyA9wiMAUiP8ABZmecjoaW0qAAAAAElFTkSuQmCC");
}
.slideshow .ninja-slider.fullscreen .fs-icon::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACmklEQVR4XtXb21HDMBCF4ZMKoBPoAOgAOoFKoBPoAOgAOoEOmM2MGMVeO7rsWWn96LGT/F8SX2T7AH26BvAM4AHAz8YyUWZfAngF8ATga/mhD0qFxL8DkBVlhbvACNIgLdIkX6S0nCAsAfL4ZBMVIY9PLSuEHECLj4qgxasICWAvPhrCXvwKQQBK4qMglMSfIAjAB4Cbik36rNuEmviU+ykAsqIgXAVGaIn/BnCbtgGREZrjZdeY7wUiInTFyy9+eRwQCaE7XgOQeREQTOK3AGZHMIvfA5gVwTT+HMBsCObxJQCzIFDiSwFGI9DiawBGIVDjawG8EejxLQBeCC7xrQBsBLf4HgAWgmt8L4A1gnu8BYAVwpB4K4BeBFk/DV2XjskcBzMshuu16wKlH2K5XOtZpLyOjEuWTmbxlr+A9OFbEErDZTnTeAZA69+hBME8ngXAQKDEMwEsEWjxbAALBGq8B0APAj3eE6B2Py+fzeUKlOVxgLYlbznCy1+HjsAE6I1PEFQEFoBVPB2BAWAdT0WwBmDF0xAsAVriZVcn07BL81YArfFySivTsPsTLAB64tM9iC1nkSZ7h14Ai/ieU+luhB4Ay/hhCK0AjPghCC0AzHh3hFoAj3hXhBoAz3g3hFKAEfEuCCUAI+PpCOcAZoinIuwBzBRPQ9gCmDGegqABzBxvjqDdKls7gOkyeqsMOJqcQC1vlo4Sb/ZLyG+XjxZvgpAemIga343Q8sjMqP+8dt0hn9eyTTg+MiM3J8iQ1MW5d2Bcny94z5pFahB+80dmShBm/eaXQCUIx3i5/JbvBfYQosSXbBP+42Xh5XGAhhAtfg/hJF4DkHk5QtR4DWEVvwWQEF4A3FvcilazFSMsK9uENwCP2uPzf1vpTFBMQCPiAAAAAElFTkSuQmCC");
}
.slideshow .ninja-slider .slider-inner {
  max-width: 100%;
}
.slideshow .ninja-slider .ns-img {
  background-size: cover;
  border-radius: 0;
  left: 0;
}
.slider-inner ul li .titleSlide {
  position: absolute;
  z-index: 1;
  display: table;
  margin: 0 auto;
  vertical-align: middle;
  width: 55%;
  left: 30%;
  top: 20px;
  right: 15%;
}
.slider-inner ul li .titleSlide .titleOffer {
  display: table-cell;
  width: auto;
  text-align: left;
  vertical-align: middle;
}
.slider-inner ul li .titleSlide .titleOffer .titleOfferTxt {
  font-size: 3em;
  line-height: 1;
  color: white;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  vertical-align: middle;
}
.slider-inner ul li .titleSlide .titleBtn {
  display: table-cell;
  vertical-align: middle;
}
.slider-inner ul li .titleSlide .titleBtn a.btnSlide {
  padding: 10px 25px;
  background: #2196f3;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  vertical-align: middle;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
}
#ptw-payment-options table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  vertical-align: middle;
}
#ptw-payment-options tr:last-child td {
  border-bottom: none;
}
#ptw-payment-options h2,
#ptw-payment-options p {
  margin: 0;
}
#ptw-payment-options h3 {
  margin: 15px 0 10px 0;
}
#ptw-payment-options td.ptw-bank-img {
  padding: 5px 0;
}
#ptw-payment-options td.ptw-method-info {
  line-height: 1.3em;
  max-width: 30%;
  padding: 5px 5px 5px 0;
}
#ptw-payment-options td.ptw-method-info strong {
  font-size: 1.4em;
}
.ptw-sprite-payments {
  background: url(../images/sprite-payments.png) no-repeat;
}
.ptw-bank0 {
  background-position: -360px 0px;
  width: 18px;
  height: 17px;
}
.ptw-bank2 {
  background-position: 0px 0px;
  width: 18px;
  height: 16px;
}
.ptw-bank3 {
  background-position: -18px 0px;
  width: 16px;
  height: 16px;
}
.ptw-bank4 {
  background-position: -34px 0px;
  width: 18px;
  height: 16px;
}
.ptw-bank5 {
  background-position: -52px 0px;
  width: 26px;
  height: 16px;
}
.ptw-bank6 {
  background-position: -78px 0px;
  width: 16px;
  height: 16px;
}
.ptw-bank7 {
  background-position: -94px 0px;
  width: 17px;
  height: 16px;
}
.ptw-bank8 {
  background-position: -111px 0px;
  width: 16px;
  height: 16px;
}
.ptw-bank9 {
  background-position: -165px 0px;
  width: 16px;
  height: 16px;
}
.ptw-bank10 {
  background-position: -343px 0px;
  width: 16px;
  height: 16px;
}
.ptw-bank11 {
  background-position: -145px 0px;
  width: 20px;
  height: 16px;
}
.ptw-bank14 {
  background-position: -219px 0px;
  width: 22px;
  height: 16px;
}
.ptw-bank18 {
  background-position: -127px 0px;
  width: 18px;
  height: 16px;
}
.ptw-bank19 {
  background-position: -241px 0px;
  width: 17px;
  height: 16px;
}
.ptw-bank21 {
  background-position: -195px 0px;
  width: 25px;
  height: 16px;
}
.ptw-bank22 {
  background-position: -181px 0px;
  width: 14px;
  height: 16px;
}
.ptw-bank23 {
  background-position: -312px 0px;
  width: 31px;
  height: 16px;
}
.ptw-bank24 {
  background-position: -258px 0px;
  width: 22px;
  height: 16px;
}
.ptw-bank25 {
  background-position: -296px 0px;
  width: 16px;
  height: 16px;
}
.ptw-bank26 {
  background-position: -280px 0px;
  width: 16px;
  height: 16px;
}
.ptw-paypal {
  background-position: -120px -91px;
  width: 64px;
  height: 18px;
}
.pt-customJqueryUi .ptw-resultPrediction {
  list-style: none;
  min-width: 320px;
  margin: 0;
  padding: 0;
  border: 1px solid #ddd;
  box-shadow: 1px 1px 3px 1px #eee;
  display: inline-block;
}
.pt-customJqueryUi .ptw-resultPrediction li {
  list-style: none;
  padding: 7px 10px 7px 10px;
  overflow: hidden;
}
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-destination:before,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-zone:before,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-interestPoint:before,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-airport:before,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-hotel:before,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-tour:before,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-city:before {
  display: block;
  height: 0;
  width: 0;
  left: 3px;
  top: 6px;
  position: relative;
  font-size: 1.2em;
  color: #bdc3c7;
}
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-destination a,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-zone a,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-interestPoint a,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-airport a,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-hotel a,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-tour a,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-icon-places-city a {
  padding: 5px 0 5px 25px !important;
}
.pt-customJqueryUi .ptw-resultPrediction li .tip-legend {
  color: #ed1556;
  font-size: 1.2em;
}
.pt-customJqueryUi .ptw-resultPrediction li div.name,
.pt-customJqueryUi .ptw-resultPrediction .ptw-item-name {
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.2em;
}
.pt-customJqueryUi .ptw-resultPrediction li span.location,
.pt-customJqueryUi .ptw-resultPrediction .ptw-item-location {
  font-size: 0.99em;
  color: #a9afb2;
  display: block;
}
.pt-customJqueryUi .ptw-resultPrediction li.more:hover {
  text-decoration: underline;
}
.pt-customJqueryUi .ptw-resultPrediction li.title,
.pt-customJqueryUi .ptw-resultPrediction li.title:hover,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title:hover {
  padding: 5px 10px 4px 10px;
  text-transform: uppercase;
  color: #777;
  font: bold 1em/110% sans-serif;
  background: #f5f5f5;
  border-top: solid #f5f5f5 1px;
  cursor: default;
}
.pt-customJqueryUi .ptw-resultPrediction li.title i,
.pt-customJqueryUi .ptw-resultPrediction li.title:hover i,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title i,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title:hover i {
  font-size: 1.4em;
  float: left;
}
.pt-customJqueryUi .ptw-resultPrediction li.title i.ptw-icon-places-destination,
.pt-customJqueryUi .ptw-resultPrediction li.title i.ptw-icon-places-tour,
.pt-customJqueryUi .ptw-resultPrediction li.title i.ptw-icon-places-city,
.pt-customJqueryUi .ptw-resultPrediction li.title i.ptw-icon-places-zone,
.pt-customJqueryUi .ptw-resultPrediction li.title i.ptw-icon-places-interestPoint,
.pt-customJqueryUi .ptw-resultPrediction li.title i.ptw-icon-places-airport,
.pt-customJqueryUi .ptw-resultPrediction li.title i.ptw-icon-places-hotel,
.pt-customJqueryUi .ptw-resultPrediction li.title:hover i.ptw-icon-places-destination,
.pt-customJqueryUi .ptw-resultPrediction li.title:hover i.ptw-icon-places-tour,
.pt-customJqueryUi .ptw-resultPrediction li.title:hover i.ptw-icon-places-city,
.pt-customJqueryUi .ptw-resultPrediction li.title:hover i.ptw-icon-places-zone,
.pt-customJqueryUi .ptw-resultPrediction li.title:hover i.ptw-icon-places-interestPoint,
.pt-customJqueryUi .ptw-resultPrediction li.title:hover i.ptw-icon-places-airport,
.pt-customJqueryUi .ptw-resultPrediction li.title:hover i.ptw-icon-places-hotel,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title i.ptw-icon-places-destination,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title i.ptw-icon-places-tour,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title i.ptw-icon-places-city,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title i.ptw-icon-places-zone,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title i.ptw-icon-places-interestPoint,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title i.ptw-icon-places-airport,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title i.ptw-icon-places-hotel,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title:hover i.ptw-icon-places-destination,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title:hover i.ptw-icon-places-tour,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title:hover i.ptw-icon-places-city,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title:hover i.ptw-icon-places-zone,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title:hover i.ptw-icon-places-interestPoint,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title:hover i.ptw-icon-places-airport,
.pt-customJqueryUi .ptw-resultPrediction li.ptw-item-title:hover i.ptw-icon-places-hotel {
  color: #95a5a6;
  margin-left: 2px;
}
.pt-customJqueryUi .ptw-resultPrediction li.first {
  border-top: none !important;
  border-top: 1px solid #f0f0f0;
}
.pt-customJqueryUi .ptw-resultPrediction li.first i {
  display: block;
  padding-bottom: 0px;
  font-size: 1.3em;
  line-height: 1;
  float: left;
  top: 1px;
  left: -3px;
  position: relative;
  margin-right: 2px;
}
.pt-customJqueryUi .ptw-resultPrediction li.first i.ptw-icon-places-destination,
.pt-customJqueryUi .ptw-resultPrediction li.first i.ptw-icon-places-tour,
.pt-customJqueryUi .ptw-resultPrediction li.first i.ptw-icon-places-city,
.pt-customJqueryUi .ptw-resultPrediction li.first i.ptw-icon-places-zone,
.pt-customJqueryUi .ptw-resultPrediction li.first i.ptw-icon-places-interestPoint,
.pt-customJqueryUi .ptw-resultPrediction li.first i.ptw-icon-places-airport,
.pt-customJqueryUi .ptw-resultPrediction li.first i.ptw-icon-places-hotel {
  color: #bdc3c7;
  margin-left: 2px;
}
.pt-customJqueryUi .ptw-resultPrediction li.first .places-itemCount {
  color: #7f8c8d;
  margin-left: 4px;
}
.pt-customJqueryUi .ptw-resultPrediction li.first .ui-state-focus:hover .places-itemCount {
  color: #ffffff;
}
.pt-customJqueryUi .ptw-resultPrediction li.first a {
  padding: 2px 5px;
}
.pt-customJqueryUi .ptw-resultPrediction li:hover,
.pt-customJqueryUi .ptw-resultPrediction li.default {
  background-color: #d6e6f6;
  cursor: pointer;
}
.pt-customJqueryUi .ptw-resultPrediction li.disabled,
.pt-customJqueryUi .ptw-resultPrediction li.disabled span.location {
  color: rgba(0, 0, 0, 0.4);
}
.pt-customJqueryUi .ptw-resultPrediction li.disabled span.location {
  font-style: italic;
}
.pt-customJqueryUi .ptw-resultPrediction li.disabled:hover {
  background: #fff;
  cursor: default;
}
.pt-customJqueryUi .ptw-resultPrediction li.tip-legend {
  color: #ed1556;
}
.pt-customJqueryUi .ptw-resultPrediction .name b {
  display: inline;
}
.pt-customJqueryUi .ptw-icon-places-destination:before {
  content: "\1f310";
}
.pt-customJqueryUi .ptw-icon-places-tour:before {
  font-family: $icon-font-family !important;
  content: $icons-photo-camera;
}
.pt-customJqueryUi .ptw-icon-places-city:before {
  font-family: $icon-font-family !important;
  content: $icons-location;
}
.pt-customJqueryUi .ptw-icon-places-zone:before {
  content: "\2600";
}
.pt-customJqueryUi .ptw-icon-places-interestPoint:before {
  content: "\1f3db";
}
.pt-customJqueryUi .ptw-icon-places-airport:before {
  content: "\2708";
}
.pt-customJqueryUi .ptw-icon-places-hotel:before {
  font-family: "ptw-icons";
  content: "\e610";
}

/*Spinner*/
/*Splash*/
.landing-home #splash {
  margin-top: 20px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #06c;
  animation: spin 1s ease infinite;
  margin-top: 30px;
}

/*Landings de ofertas*/
.render .go1-banner-search {
  background-position: initial !important;
}

/*Etiqueta en el calendario*/
.input-group-icon {
  display: none;
  position: absolute;
  z-index: 2;
  top: 3px;
  right: 40px;
}

.btn-delete {
  background: none;
  border: none;
}
.ui-state-default .input-group-icon {
  display: block;
}
.range-plugin-tooltip {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  color: #333;
  font-size: 12px;
  margin-top: -4px;
  padding: 4px 8px;
  pointer-events: none;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  z-index: 900000;
  background: white;
}
.range-plugin-tooltip:before {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.12);
  bottom: -5px;
  content: "";
  left: calc(50% - 5px);
  position: absolute;
}
.range-plugin-tooltip:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
  bottom: -4px;
  content: "";
  left: calc(50% - 4px);
  position: absolute;
}

/*Busqueda avanzada*/
#option-flights {
  display: inline-flex;
  width: 100%;
}
span.multiselect-native-select {
  position: relative;
}
span.multiselect-native-select select {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px -1px -1px -3px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  left: 50%;
  top: 30px;
}
.multiselect.dropdown-toggle:after {
  display: none;
}
.multiselect {
  overflow: hidden;
  text-overflow: ellipsis;
}
.multiselect-container {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.multiselect-container .multiselect-reset .input-group {
  width: 93%;
}
.multiselect-container .multiselect-filter > .fa-search {
  z-index: 1;
  padding-left: 0.75rem;
}
.multiselect-container .multiselect-filter > input.multiselect-search {
  border: none;
  border-bottom: 1px solid lightgrey;
  padding-left: 2rem;
  margin-left: -1.625rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.multiselect-container .multiselect-filter > input.multiselect-search:focus {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.multiselect-container .multiselect-filter > .multiselect-moz-clear-filter {
  margin-left: -1.5rem;
  display: none;
}
.multiselect-container .multiselect-option.multiselect-group-option-indented {
  padding-left: 1.75rem;
}
.multiselect-container .multiselect-option,
.multiselect-container .multiselect-group,
.multiselect-container .multiselect-all {
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
}
.multiselect-container .multiselect-option.dropdown-item,
.multiselect-container .multiselect-group.dropdown-item,
.multiselect-container .multiselect-all.dropdown-item,
.multiselect-container .multiselect-option.dropdown-toggle,
.multiselect-container .multiselect-group.dropdown-toggle,
.multiselect-container .multiselect-all.dropdown-toggle {
  cursor: pointer;
}
.multiselect-container .multiselect-option .form-check-label,
.multiselect-container .multiselect-group .form-check-label,
.multiselect-container .multiselect-all .form-check-label {
  cursor: pointer;
}
.multiselect-container .multiselect-option.active:not(.multiselect-active-item-fallback),
.multiselect-container .multiselect-group.active:not(.multiselect-active-item-fallback),
.multiselect-container .multiselect-all.active:not(.multiselect-active-item-fallback),
.multiselect-container .multiselect-option:not(.multiselect-active-item-fallback):active,
.multiselect-container .multiselect-group:not(.multiselect-active-item-fallback):active,
.multiselect-container .multiselect-all:not(.multiselect-active-item-fallback):active {
  background-color: lightgrey;
  color: black;
}
.multiselect-container .multiselect-option:hover,
.multiselect-container .multiselect-group:hover,
.multiselect-container .multiselect-all:hover,
.multiselect-container .multiselect-option:focus,
.multiselect-container .multiselect-group:focus,
.multiselect-container .multiselect-all:focus {
  background-color: darkgray !important;
}
.multiselect-container .multiselect-option .form-check,
.multiselect-container .multiselect-group .form-check,
.multiselect-container .multiselect-all .form-check {
  padding: 0 5px 0 20px;
}
.multiselect-container .multiselect-option:focus,
.multiselect-container .multiselect-group:focus,
.multiselect-container .multiselect-all:focus {
  outline: none;
}
#option-flights .form-inline .multiselect-container span.form-check {
  padding: 3px 20px 3px 40px;
}
#option-flights .input-group.input-group-sm > .multiselect-native-select .multiselect {
  padding: 0.25rem 0.5rem;
  font-size: 1.2rem;
  line-height: 1.5;
  padding-right: 1.75rem;
  height: calc(4em);
}
#option-flights .input-group > .multiselect-native-select {
  flex: 1 1 auto;
  width: 1%;
}
#option-flights .input-group > .multiselect-native-select > div.btn-group {
  width: 100%;
}
#option-flights .input-group > .multiselect-native-select:not(:first-child) .multiselect {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
#option-flights .input-group > .multiselect-native-select:not(:last-child) .multiselect {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .landing-home #splash img {
    width: 100%;
    padding: 10px;
  }
}

/*Imagen splash*/
#splash.defaultSplash img {
  max-width: 100%;
}

/*Busqueda avanzada*/
.form-select {
  height: 35px;
  margin-right: 6px !important;
  padding: 0 10px !important;
}
#btn-group-airlinesmultiselect botton {
  border-radius: 3px;
  border: 1px solid #e5e5e5 !important;
}
#non-stop-flights {
  line-height: 35px;
  margin-left: 5px;
  display: ruby;
}
.multi li {
  padding: 1% 10% !important;
}
.multi p {
  margin-top: 30px !important;
  width: 100%;
  display: block;
}
.content-airlines-search .hidden-search {
  display: none !important;
}
.content-airlines-search li[data-parent].closed {
  display: none !important;
}
.content-airlines-search li[data-parent].open:not(.hidden-search) {
  display: block !important;
}
.vsb-menu {
  cursor: pointer;
  z-index: 1000;
  display: block;
  visibility: hidden;
  position: absolute; /*Don't change*/
  border: 1px solid #ddd;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-radius: 4px;
  font-size: 12px;
  padding: 8%;
}
.vsb-js-search-zone {
  position: absolute; /*Don't change*/
  z-index: 1001;
  width: 80%;
  min-height: 1.8em;
  padding: 2px;
  background-color: #fff;
}
.vsb-js-search-zone input {
  border: 1px solid grey;
  margin-left: 2px;
  width: 96%;
  border-radius: 4px;
  height: 25px !important;
}
.vsb-main {
  position: relative; /*Don't change*/
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.vsb-menu li:hover {
  background: linear-gradient(#f5f5f5, #e8e8e8);
}
.vsb-menu ul {
  user-select: none;
  list-style: none;
  white-space: nowrap;
  margin: 0px;
  margin-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  color: #333;
  cursor: pointer;
  overflow-y: auto;
}
.content-airlines-search li.disabled {
  cursor: not-allowed;
  opacity: 0.3;
  background-color: #999;
}
.content-airlines-search li.overflow {
  cursor: not-allowed;
  opacity: 0.3;
  background-color: #999;
}
.content-airlines-search li.short {
  overflow: hidden;
  text-overflow: ellipsis;
}
.vsb-main button {
  min-width: 120px;
  border-radius: 3px;
  width: 100%;
  text-align: left;
  z-index: 1;
  color: #333;
  background: white !important;
  border: 1px solid #e5e5e5 !important;
  line-height: 20px;
  font-size: 14px;
  padding: 6px 12px;
}
.vsb-main button.disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
.vsb-main .title {
  margin-right: 6px;
  user-select: none;
}
.vsb-main li:hover {
  background: #f2f2f2;
}
.vsb-main ul {
  white-space: nowrap;
}
.vsb-menu li {
  font-size: 14px;
  background-color: #fff;
  min-height: 1.4em;
  padding: 0.2em 2em 0.2em 1em;
}
.vsb-menu li.grouped-option b {
  display: inline-block;
  font-size: 15px;
  margin-left: 10px;
  transform: translate(-18px);
}
.vsb-menu li.grouped-option.open span {
  display: inline-block;
  font-size: inherit;
  margin-top: -2px;
  height: 8px;
  width: 8px;
  transform: translate(-38px) rotate(45deg);
  border-bottom: 3px solid black;
  border-right: 3px solid black;
  border-radius: 2px;
}
.vsb-menu li.grouped-option.closed span {
  display: inline-block;
  font-size: inherit;
  height: 8px;
  width: 8px;
  transform: translate(-38px) rotate(-45deg);
  border-bottom: 3px solid black;
  border-right: 3px solid black;
  border-radius: 2px;
}
.vsb-menu li.grouped-option i {
  display: inline-block;
  font-size: inherit;
  float: left;
  font-weight: bold;
  margin-left: 22px;
  margin-right: 2px;
  height: 11px;
  width: 8px;
  border: 1px solid;
  border-radius: 3px;
  padding: 1px 3px 2px 3px;
  margin-top: 0px;
  color: black;
}
.vsb-menu li.grouped-option.checked i::after {
  content: "";
  display: inline-block;
  font-size: inherit;
  color: #333;
  float: left;
  margin-left: 0px;
  display: inline-block;
  transform: rotate(45deg);
  height: 8px;
  width: 5px;
  border-bottom: 3px solid black;
  border-right: 3px solid black;
}
.vsb-menu :not(.multi) li.active {
  margin-left: 7px;
}
.vsb-menu :not(.multi) li.active::before {
  content: "";
  display: inline-block;
  font-size: inherit;
  margin-left: -18px;
  transform: rotate(45deg);
  height: 10px;
  width: 5px;
  border-bottom: 3px solid black;
  border-right: 3px solid black;
  border-radius: 2px;
}
.vsb-menu .multi li {
  font-size: 14px;
  background-color: #fff;
  min-height: 1.4em;
  padding: 0.2em 2em 0.2em 26px;
}
.vsb-menu .multi li.grouped-option {
  font-size: 15px;
  padding-left: 5px;
}
.vsb-menu .multi li.grouped-option:hover {
  font-weight: bold;
  text-decoration: underline;
  color: rgb(52, 31, 112);
}
.vsb-menu .multi li:not(.grouped-option)::before {
  content: "";
  display: inline-block;
  font-size: inherit;
  float: left;
  font-weight: bold;
  margin-left: -22px;
  margin-right: 2px;
  border: 1px solid;
  border-radius: 3px;
  padding: 7px;
  margin-top: 0px;
  color: #999;
}
.vsb-menu .multi li:not(.grouped-option).active::after {
  content: "";
  display: inline-block;
  font-size: inherit;
  color: #333;
  float: left;
  margin-left: -18px;
  display: inline-block;
  transform: rotate(45deg);
  margin-top: 3px;
  height: 8px;
  width: 5px;
  border-bottom: 3px solid black;
  border-right: 3px solid black;
}
.vsb-menu .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.vsb-menu li[data-parent] {
  padding-left: 50px !important;
}
#search_airlinesmultiselect {
  width: 100%;
  display: block;
  float: left;
}
#search_airlinesmultiselect input {
  border: 1px solid #ccc;
}
#wrapper .close {
  display: none;
}
#wrapper .form-select {
  width: 100%;
}
#wrapper .vsb-main {
  min-width: 220px;
  border-radius: 3px !important;
  border: 1px solid #e5e5e5 !important;
  margin: 5px 0;
}
#wrapper #option-flights {
  display: inline;
  width: 100%;
  float: left;
}
#wrapper #non-stop-flights {
  line-height: normal;
  margin: 10px 0 15px 2px;
}

@media (max-width: 500px) {
  .vsb-menu {
    float: left;
    left: -55px;
  }
}

.widget__button--switch-places{
  display: none;
}